import React from "react";
import styles from "./PrivateQuotationItem.module.scss";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../../assets/images/argentinaIcon.jpg"; 
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import { ReactComponent as ClockIcon } from "../../../assets/svg/clockIcon.svg";
import { deleteProyecto } from "../../../store/actions";
import { connect } from "react-redux";
import { iconJPG } from "../../UI/ProvinceIcons/IconsData";

const PrivateQuotationItem = ({ quotation }) => {

    const navigate = useNavigate();
    const coverImage = DefaultImage;

    const handleRedirectToProject = () => {
        navigate(`/cotizaciondemateriales/${quotation.id}`);
    };

    // Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const options = { day: 'numeric', month: 'short' };
        return utcDate.toLocaleDateString('es-ES', options);
    };

    // Obtener el ícono de la jurisdicción
    const getJurisdictionIcon = (jurisdictionName) => {
        const foundIcon = iconJPG.find(icon => icon.name === jurisdictionName);
        return foundIcon ? foundIcon.icon : null; 
    };

    const jurisdictionIcon = getJurisdictionIcon(quotation?.jurisdiccion?.name);

    const zoneToShow =  quotation?.zone ? quotation?.zone : quotation?.jurisdiccion?.name;

    return (
        <div className={styles.container}>
            <div className={styles.container_image}>
                <img className={styles.container_image_img} src={jurisdictionIcon ? jurisdictionIcon : coverImage} alt={quotation?.jurisdiction?.name} />
                <p className={styles.container_info_location}>
                    {zoneToShow}
                </p>
                <p className={styles.container_info_dates}>
                    {formatDate(quotation?.startDate)} - {formatDate(quotation?.endDate)}
                </p>
            </div>
            <div className={styles.container_info}>
                <div className={styles.container_info_text}>
                    <div className={styles.container_info_text_pill} />
                    <h4 className={styles.container_info_text_title}>{quotation?.name}</h4>
                    <p className={styles.container_info_text_description}>{quotation?.description}</p>
                </div>
                <div className={styles.container_info_footer}>
                    <div className={styles.container_info_footer_clock}>
                        <ClockIcon width={15} height={15} />
                        <relative-time lang="es" tense="past" datetime={quotation?.createdAt}></relative-time>
                    </div>
                    <div className={styles.container_info_footer_comments}>
                    </div>
                </div>
            </div>
            <div className={styles.container_buttons}>
                <div className={styles.container_buttons_title} onClick={handleRedirectToProject}>
                    <p>Ver detalles</p>
                    <ArrowRightIcon height={20} width={20} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, { deleteProyecto })(PrivateQuotationItem);
