import React, { useState, useEffect } from "react";
import styles from "./MisProductos.module.scss";
import { connect } from "react-redux";
import {
    getPortfolio,
} from "../../../../store/actions/index";
import { motion } from "framer-motion";
import { ReactComponent as AddProyect } from "../../../../assets/svg/crearPP.svg";
import { ReactComponent as Search } from "../../../../assets/svg/misProyectosLittle.svg";
import EmptyState from "../../../UI/EmptyState/EmptyState";
import usePremiumCheck from "../../../../hooks/usePremiumCheck";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/svg/arrowDownIcon.svg";
import NuevoProducto from "./NuevoProducto/NuevoProducto";
import PortfolioItem from "../../../MainInfoItem/PortfolioItem/PortfolioItem";


const MisProductos = ({
    portfolios,
    perfilAjeno,
    getPortfolio,
    myPerson,
    userData,
    setIsCreatingNewPortfolio,
    setCreateNewPortfolio,
    isCreatingNewPortfolio,
    createNewPortfolio,
    handleNewPortfolioClick,
    ...props
}) => {
    const [visibleItems, setVisibleItems] = useState(8);

    useEffect(() => {
        getPortfolio(perfilAjeno);
      }, [perfilAjeno, getPortfolio]);


    const premiumCheck = usePremiumCheck();

    
  //Maneja la cantidad de portfolios a mostrar 
  const handleToggleVisibility = () => {
    setVisibleItems(prevVisibleItems => {
      if (prevVisibleItems === portfolios.length) {
        return 4; 
      } else {
        return portfolios.length; 
      }
    });
  };


    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.container}
        >
            {createNewPortfolio && (
                <NuevoProducto cancel={() => handleNewPortfolioClick(false)} isCreatingNewPortfolio={isCreatingNewPortfolio} />
            )}
            {portfolios && portfolios.length > 0 ? (
                <div className={styles.container_body}>
                    {portfolios.map((portfolio) => (
                        <PortfolioItem
                            key={portfolio.id}
                            portfolio={portfolio}
                            fromPage={props.fromPage}
                            perfilAjeno={perfilAjeno}
                            type="portfolio"
                            id={portfolio.id}
                            isPortfolio
                            myPerson={myPerson}
                            userData={userData}
                        />
                    ))}
                </div>
            ) : (
                myPerson ? (
                    <div className={styles.emptyState} onClick={handleNewPortfolioClick} >
                <EmptyState title="No tienes productos o servicios publicados ¡Crea el primero!" icon={<AddProyect width={50} height={50}/> } />
                </div>
                ) : (       
                    <EmptyState title="Este usuario no tiene proyectos publicados" icon={<Search width={50} height={50} />} />
                )             
            )}
            {portfolios?.length > 0 && (
                    portfolios?.length > visibleItems ? (
                      <div className={styles.PerfilMain_content_seeMore} onClick={handleToggleVisibility}>
                        <ArrowDownIcon height={8} width={8} className={portfolios.length > visibleItems ? styles.arrowDownIcon : styles.arrowUpIcon} />
                        Mostrar más
                        <ArrowDownIcon height={8} width={8} className={portfolios.length > visibleItems ? styles.arrowDownIcon : styles.arrowUpIcon}/>
                        </div>
                    ) : (
                      portfolios?.length > 8 &&
                      <div className={styles.PerfilMain_content_seeMore} onClick={handleToggleVisibility}>
                        <ArrowDownIcon height={8} width={8} className={styles.arrowUpIcon} />
                        Ocultar
                        <ArrowDownIcon height={8} width={8} className={styles.arrowUpIcon}/>
                        </div>
                    )
                  )}
        </motion.section>
    );
};

const mapStateToProps = (state) => {
    return {
        portfolios: state.register.portfolios,
    };
};

export default connect(mapStateToProps, {
    getPortfolio,
})(MisProductos);