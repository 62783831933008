import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from "formik";
import styles from "./NuevoProducto.module.scss";
import {
  openMainModal,
  closeMainModal,
  setPortfolio,
} from "../../../../../store/actions";
import { ReactComponent as PortfolioSVG } from "../../../../../assets/svg/portfolio-profile.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/svg/xClose.svg";
import DetailsEdit from "../../../../UI/DetailsEdit/DetailsEdit";
import * as Yup from "yup";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    //height: "auto",
    transition: { delay: 0.2 },
  },
};



const NuevoProducto = ({ isCreatingNewPortfolio, ...props }) => {
  const [newImage, setNewImage] = useState({});
  const [messageError, setMessageError] = useState("");
  const [imageEdit, setImageEdit] = useState([]);


  //Array de imagenes
  let imageFileEdit = [];


  const PortfolioSchema = Yup.object().shape({
    nameEdit: Yup.string().required("El campo es requerido"),
    descriptionEdit: Yup.string().required("El campo es requerido"),
    startDateEdit: Yup.date().required("El campo es requerido"),
    endDateEdit: Yup.date().required("El campo es requerido"),
    zoneEdit: Yup.string().required("El campo es requerido"),
    imageEdit: Yup.array().min(1, "Debe subir al menos una imagen").required("El campo es requerido"),
  });

  const initialValues = {
    nameEdit: "",
    startDateEdit: "",
    endDateEdit: "",
    descriptionEdit: "",
    zoneEdit: "",
    imageEdit: [],
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PortfolioSchema,
    validateOnMount: true,
  });

  const createHandler = () => {
    if (formik.values.startDateEdit > formik.values.endDateEdit) {
      setMessageError("La fecha de inicio no puede ser mayor a la del cierre");
    } else if (!formik.values.startDateEdit || !formik.values.endDateEdit) {
      setMessageError("Ingrese fecha de inicio y fecha de fin");
    } else if (!formik.values.nameEdit) {
      setMessageError("Ingrese nombre");
    } else if (!formik.values.descriptionEdit) {
      setMessageError("Ingrese Descripción");
    } else {
      setMessageError("");
      props.openMainModal({
        title: "¿Estas seguro que deseas crear el producto - servicio dentro de tu portfolio?",
        body: "Una vez creado será publicado en tu perfil y podrá ser visto por toda la comunidad.",
        isSuccess: false,
        confirmHandler: () => {
          props.setPortfolio(formik.values, imageEdit, "perfil");
          props.closeMainModal();
        },
      });
    }
  };

  //Setea la imagen a enviar
  const handleImageFilesChange = (file) => {
    setImageEdit(file);
  };

  //Maneja el estado del array de imagenes a enviar
  const handleImageEditChange = (newImageEdit) => {
    setImageEdit(newImageEdit);
  };


  return (
    <AnimatePresence>
      <motion.div
        key="1"
        variants={backdrop}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className={styles.newPortfolioModal_overlay}
      >
        <motion.div
          key="2"
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.newPortfolioModal}
        >
          <motion.div
            initial={{ height: "0" }}
            animate={{ height: "auto" }}
            exit={{ height: "0" }}
            transition={{ damping: 5, height: { duration: 0.5 } }}
            className={styles.container}
          >
            <header className={styles.container_heading}>      
              <div className={styles.container_heading_txt}>
                <PortfolioSVG width={15} height={15}/>
                Carga de portfolio</div>
              <div className={styles.container_heading_cancel} onClick={props.cancel}>
              <CloseIcon width={15} height={15}/>
              </div>
            </header>
            <motion.div>
              <DetailsEdit
                values={formik.values}
                onImageFilesChange={handleImageFilesChange}
                formik={formik}
                newImage={newImage}
                isCreatingNewPortfolio={isCreatingNewPortfolio}
                isPortfolio="true"
                imageEdit={imageEdit}
                imageFileEdit={imageFileEdit}
                setImageEdit={handleImageEditChange}
              />
            </motion.div>
            <div
              className={`${styles.container_submit} ${!formik.isValid ? styles.disabled : ""
                }`}
              onClick={() => createHandler()}
            >
              Publicar en mi perfil
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default connect(null, { openMainModal, setPortfolio, closeMainModal })(
  NuevoProducto
);
