import React, { useEffect } from "react";
import styled from "styled-components";
import { 
  generateVisit,
  getProfileVisualizer,
  getProfileData,
} from "../../store/actions";
import PerfilMain from "../../components/Perfil/PerfilMain/PerfilMain";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const RegisteredUserProfile = ({
  generateVisit,
  getProfileVisualizer,
  profileVisualizerData,
  getProfileData,
  userData,
   ...props }) => {

  const { id } = useParams();

  const userId = id === userData.id ? userData.id : id;

  const myPerson = userId === userData.id

  //Genera la visita en el perfil
  useEffect(() => {
    if (!myPerson) {
      generateVisit(parseInt(userId));
    }
  }, []);

useEffect(() => {
  if (!myPerson) {
    getProfileVisualizer(userId);
  } else {
    getProfileData(userId);
  }
}, [userId]);
   
  return (
      <Container>             
          <PerfilMain 
            perfilAjeno={userId}  
            profileVisualizerData={profileVisualizerData} 
            userData={userData}
            myPerson={myPerson} />
      </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    profileVisualizerData: state.general.profileVisualizer,
  };
};

export default connect(mapStateToProps, { 
  generateVisit,
  getProfileVisualizer,
  getProfileData,
 })(RegisteredUserProfile);
