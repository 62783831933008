import React from "react";
import styles from "./Alquiler.module.scss";
import styled from "styled-components";
import MarketModal from "../UI/MainModal/MarketModal";
import { connect } from "react-redux";
import {
  getDashboardProjects,
  getProjects,
  getPostulations,
  resetFilterBy,
  openFeedTextModal,
} from "../../store/actions";


const Container = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 100%;
  min-width: 760px;
  max-width: 760px;

  @media (max-width: 900px) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }
`;

const ProjectsPostulationsWrapper = styled.div`
  position: sticky;
  top: 98px;
  left: 0;
  height: 100%;
  display: flex;
  min-width: 313px;
  max-width: 313px;
  justify-content: center;
  @media (max-width: 900px) {
    display: none;
  }
`;
const OverflowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  margin-bottom: 2rem;
  margin-top: 30px;
  min-width: 283px;
  max-width: 283px;
  @media (max-width: 900px) {
    display: none;
  }
`; 

const StylesMenu = styled.div`
  position: absolute;
`;

const Alquiler = ({
}) => {

  return (
    <>
      <Container>
        <Wrapper>
          <div className={styles.container}>
            <MarketModal />           
          </div>
        </Wrapper>
        <ProjectsPostulationsWrapper>
          <OverflowWrapper>
          </OverflowWrapper>
        </ProjectsPostulationsWrapper>
      </Container>
    </>
  );
};


const mapStateToProps = (state) => ({
  allProjects: state.dashboard.viewProjects,

  proyectos: state.profile.proyectos,
  postulaciones: state.profile.postulations,
  isLoaded: state.general.isLoaded,
});

export default connect(mapStateToProps, {
  getDashboardProjects,
  getProjects,
  getPostulations,
  resetFilterBy,
  openFeedTextModal,
})(Alquiler);
