import React, { useState, useEffect } from "react";
import styles from "./MisProyectos.module.scss";
import { connect } from "react-redux";
import {
    getProjects,
    closeMainModal,
} from "../../../../store/actions/index";
import NewProject from "../../../NewProject/NewProject";
import Modal from "../../../../components/Modal/Modal";
import { motion } from "framer-motion";
import { ReactComponent as AddProyect } from "../../../../assets/svg/crearPP.svg";
import { ReactComponent as Search } from "../../../../assets/svg/misProyectosLittle.svg";
import EmptyState from "../../../UI/EmptyState/EmptyState";
import usePremiumCheck from "../../../../hooks/usePremiumCheck";
import ProjectDetails from "../../../MainInfoItem/ProjectDetails/ProjectDetails";


const MisProyectos = ({
    proyectos,
    perfilAjeno,
    closeMainModal,
    getProjects,
    myPerson,
    userData,
    ...props
}) => {
    const [isOpenProject, setModalOpenProject] = useState(false);

    useEffect(() => {
        getProjects(null, myPerson ? userData.id : perfilAjeno);
    }, [userData.id, perfilAjeno]);


    const premiumCheck = usePremiumCheck();

    const proyectosToMap  = proyectos?.filter((proyecto) => proyecto.type === "PRIVATE");

    const handleNewOpenProject = () => {
        setModalOpenProject(true);
        closeMainModal();
    };

    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.container}
        >
            {proyectos && proyectosToMap.length > 0 ? (
                <div className={styles.container_body}>
                    {proyectosToMap.map((proyecto) => (
                        <ProjectDetails
                            key={proyecto.id}
                            project={proyecto}
                            isProject
                            fromPage={props.fromPage}
                            perfilAjeno={perfilAjeno}
                            type="proyecto"
                            id={proyecto.id}
                            myPerson={myPerson}
                        />
                    ))}
                </div>
            ) : (
                myPerson ? (
                    <div className={styles.emptyState} onClick={premiumCheck(handleNewOpenProject)}>
                <EmptyState title="No tienes proyectos publicados ¡Crea tu primer proyecto!" icon={<AddProyect width={50} height={50}/> } />
                </div>
                ) : (       
                    <EmptyState title="Este usuario no tiene proyectos publicados" icon={<Search width={50} height={50} />} />
                )             
            )}
            <Modal isOpen={isOpenProject}>
                <NewProject closeModal={() => setModalOpenProject(false)} />
            </Modal> 
        </motion.section>
    );
};

const mapStateToProps = (state) => {
    return {
        proyectos: state.profile.proyectos,
    };
};

export default connect(mapStateToProps, {
    getProjects,
    closeMainModal,
})(MisProyectos);







