//import React, { Component } from "react";
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrowDownIcon.svg";
import {
  setSendSubProject,
  postSubprojects,
  setProjectToEdit,
  getSubprojectsType,
  resetNewSkill,
} from "../../../../store/actions/index";
import {
  closeMainModal,
  openEdifySpinner,
} from "../../../../store/actions/profileActions";
import RubroInputSelect from "./RubroInputSelect";
import styles from "./NuevoSubproyecto.module.scss";
import { ReactComponent as Plus } from "../../../../assets/svg/plus.svg";
import { ReactComponent as Trash } from "../../../../assets/svg/description-sub.svg";
import { fireAnalytics } from "../../../../utils/firebase";

const NuevoSubproyecto = ({
  subProjectsType,
  subProjectId,
  setSendSubProject,
  projectToEdit,
  userId,
  closeModal,
  maxDateForSubProjects,
  resetNewSkill,
  minDateForSubProjects,
  ...props
}) => {
  const [clear, setClear] = useState(false);
  const [minDate, setMinDate] = useState("");
  const [showSubProjectDocus, setShowSubProjectDocus] = useState(false);
  const [showItemInfo, setShowItemInfo] = useState({});
  const [selectedRubro, setSelectedRubro] = useState("");
  const [selectFileOrlink, setSelectFileOrLink] = useState(false);

  const resetClear = () => {
    setClear(false);
  };

  const addHandler = (values, initialValues, resetForm) => {
    let { projectIdForSub, isEditing } = props;
    fireAnalytics("add_subproject", { category: "publishing" });
    if (isEditing) {
      setSendSubProject(values);
      props.postSubprojects(values, projectIdForSub, isEditing, closeModal);
      setProjectToEdit(projectIdForSub);
      resetForm(initialValues);
      resetClear();
      props.closeMainModal();
      resetNewSkill();

    } else {
      setSendSubProject(values);
      setProjectToEdit(projectIdForSub);
      resetForm(initialValues);
      resetClear();
      props.closeMainModal();
      setSelectedRubro("");
      resetNewSkill();
    }
  };


  //Validación del formulario de carga

  const subProyectosSchema = Yup.object().shape({
    nameSub: Yup.string().test(
      "is-empty",
      "El campo está vacío",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),

    descriptionSub: Yup.string().test(
      "is-empty",
      "*Si no proporciona una descripción puede que la cotización recibida no sea precisa",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),

    linkSub: Yup.string().test(
      "is-empty",
      "*Si no proporciona un link de documentación puede que la cotización recibida no sea precisa",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),

    endDateSub: Yup.date().test(
      "is-empty",
      "*Si no proporciona una fecha de cierre el usuario no sabrá hasta cuándo puede enviar la cotización",
      function (valor) {
        return typeof valor === "string" && valor.trim() !== "";
      }
    ),
  });

  const toggleShowItemInfo = (index) => {
    setShowItemInfo((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  //Seteamos fecha mínima si aún el usuario no definio la fecha mínima desde el proyecto
  useEffect(() => {
    const hoy = new Date();
    let dd = hoy.getDate();
    let mm = hoy.getMonth() + 1;
    let yyyy = hoy.getFullYear();
    const minDate = `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
    setMinDate(minDate);
  }, []);

  return (
    <div className={styles.generalContainer}>
      <Formik
        initialValues={{
          nameSub: "",
          descriptionSub: "",
          linkSub: "",
          startDateSub: "",
          endDateSub: "",
          excelBudget: null,
          excel: null,
          planosFile: null,
          metricComputation: null,
          technicalSpecifications: null,
        }}
        validationSchema={subProyectosSchema}
      >
        {({
          handleSubmit,
          isEditing,
          values,
          resetForm,
          initialValues,
          setFieldValue,
          navigate,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={`${styles.fieldWrapperSubProyect} `}>
              <RubroInputSelect
                inputName="nameSub"
                rubros={subProjectsType}
                setFieldValue={setFieldValue}
                clear={clear}
                resetClear={resetClear}
                setSelectedRubro={setSelectedRubro}
                selectedRubro={selectedRubro}
                setShowSubProjectDocus={setShowSubProjectDocus}
              />
            </div>
            <div className={values.nameSub ? styles.containerSubproject : ""}>
              <div
                className={
                  props.subProjectStyleForm
                    ? styles.fieldWrapper_hidden
                    : `${styles.fieldWrapper_content} ${values.nameSub && styles.open
                    }`
                }
              >
                {!showSubProjectDocus && (
                  <div className={styles.showDocus}>
                    <h2 className={styles.showDocus_tituloDocu}>¿Querés agregar información para obtener mejores cotizaciónes?</h2>
                    <div className={styles.showDocus_buttonContainer}>
                      <div
                        className={styles.showDocus_buttonContainer_btnNo}
                        onClick={() => {
                          addHandler(values, initialValues, resetForm);
                          setShowSubProjectDocus(false)
                        }}
                      >
                        No, solo quiero agregar el rubro
                      </div>
                      <div
                        className={styles.showDocus_buttonContainer_btnSi}
                        onClick={() => setShowSubProjectDocus(true)}
                      >
                        Sí, quiero agregar información adicional
                      </div>
                    </div>
                  </div>
                )}
                {showSubProjectDocus && (
                  <>
                    <h2 className={styles.tituloDocu}>INFORMACIÓN ADICIONAL PARA COTIZAR:</h2>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Planos</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(1)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[1] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[1] && (
                        <div>
                          <div className={styles.radioGroup}>
                            <label className={styles.radioGroup_label}>
                              <input
                                type="radio"
                                name="uploadOption"
                                value="link"
                                checked={selectFileOrlink === 'link'}
                                onChange={() => setSelectFileOrLink('link')}
                                className={styles.radioGroup_label_input}
                              />
                              Cargar link de acceso
                            </label>
                            <label className={styles.radioGroup_label} title="PDF o excel">
                              <input
                                type="radio"
                                name="uploadOption"
                                value="planosFile"
                                checked={selectFileOrlink === 'planosFile'}
                                onChange={() => setSelectFileOrLink('planosFile')}
                                className={styles.radioGroup_label_input}
                              />
                              Cargar archivo
                            </label>
                          </div>
                          {selectFileOrlink === 'link' && (
                            <div className={styles.fieldWrapperLink}>
                              <div className={styles.relative}>
                                <Field
                                  className={styles.textAreaLink}
                                  id="linkSub"
                                  name="linkSub"
                                  placeholder="Por ej: Google Drive, Dropbox, etc."
                                />
                              </div>
                            </div>
                          )}
                          {selectFileOrlink === 'planosFile' && (
                            <div className={styles.fieldWrapperLink}>
                              <div className={styles.relative}>
                                <Field
                                  className={styles.fileName}
                                  id="archivoPlanos"
                                  name="archivoPlanos"
                                  type="file"
                                  accept=".pdf, .xlsx, .xls"
                                  onChange={(event) => {
                                    event.currentTarget.files[0] &&
                                      setFieldValue("planosFile", event.currentTarget.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Cómputo metrico</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(2)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[2] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[2] && (
                        <div className={styles.fieldWrapperLink}>
                          <div className={styles.relative}>
                            <Field
                              className={styles.fileName}
                              id="computoMetrico"
                              name="computoMetrico"
                              type="file"
                              accept=".pdf, .xlsx, .xls"
                              onChange={(event) => {
                                event.currentTarget.files[0] &&
                                  setFieldValue("metricComputation", event.currentTarget.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Especificaciónes técnicas</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(3)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[3] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[3] && (
                        <div className={styles.fieldWrapperLink}>
                          <div className={styles.relative}>
                            <Field
                              className={styles.fileName}
                              id="especificacionesTecnicas"
                              name="especificacionesTecnicas"
                              type="file"
                              accept=".pdf, .xlsx, .xls"
                              onChange={(event) => {
                                event.currentTarget.files[0] &&
                                  setFieldValue("technicalSpecifications", event.currentTarget.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Planilla modelo de cotización</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(4)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[4] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[4] && (
                        <div className={styles.fieldWrapperLink}>
                          <Field
                            className={styles.fileName}
                            id="planillaExcel"
                            name="planillaExcel"
                            type="file"
                            accept=".pdf, .xlsx, .xls"
                            onChange={(event) => {
                              event.currentTarget.files[0] &&
                                setFieldValue("excelBudget", event.currentTarget.files[0]);
                            }
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.tituloDocu_itemContainer}>
                      <div className={styles.tituloDocu_itemContainer_cover}>
                        <h3 className={styles.tituloDocu_link}>Descripción particular</h3>
                        <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(5)}>
                          <ArrowIcon width={15} height={15} className={showItemInfo[5] ? styles.arrowIconDown : styles.arrowIconRight} />
                        </div>
                      </div>
                      {showItemInfo[5] && (
                        <div className={styles.fieldWrapper}>
                          <div className={styles.relative}>
                            <Field
                              id="descriptionSub"
                              name="descriptionSub"
                              placeholder="Breve descripción de las tareas a realizar."
                              as="textarea"
                              className={styles.tituloDescripcion_textArea}
                            />
                          </div>
                        </div>
                      )}
                      </div>
                      <div className={styles.tituloDocu_itemContainer}>
                        <div className={styles.tituloDocu_itemContainer_cover}>
                          <h3 className={styles.tituloDocu_link}>Fecha de recepción de presupuestos</h3>
                          <div className={styles.tituloDocu_arrow} onClick={() => toggleShowItemInfo(6)}>
                            <ArrowIcon width={15} height={15} className={showItemInfo[6] ? styles.arrowIconDown : styles.arrowIconRight} />
                          </div>
                        </div>
                        {showItemInfo[6] && (
                          <>
                            <div className={styles.fieldWrapper_startDateInput}>
                              <h5 className={styles.fechaCierre}>
                                Desde:
                              </h5>
                              <div className={styles.fieldWrapper_dateInputContainer}>
                                <Field
                                  type="date"
                                  id="startDateSub"
                                  name="startDateSub"
                                  className={styles.fieldWrapper_startDateInputPlaceholder}
                                  readOnly={false}
                                  min={minDateForSubProjects || minDate}
                                  value={minDateForSubProjects && minDateForSubProjects > values.startDateSub ? minDateForSubProjects : values.startDateSub}
                                />
                              </div>
                            </div>
                            <div className={styles.fieldWrapper_endDateInput}>
                              <h5 className={styles.fechaCierre}>
                                Hasta:
                              </h5>
                              <div className={styles.fieldWrapper_dateInputContainer}>
                                <Field
                                  type="date"
                                  id="endDateSub"
                                  name="endDateSub"
                                  placeholder="Seleccionar fecha"
                                  className={styles.fieldWrapper_endDateInputPlaceholder}
                                  readOnly={false}
                                  max={maxDateForSubProjects}
                                  value={maxDateForSubProjects && maxDateForSubProjects < values.endDateSub ? maxDateForSubProjects : values.endDateSub}
                                />
                              </div>
                            </div>
                          </>)}
                      </div>                     
                      <div className={styles.buttonContainer}>
                        <button
                          className={styles.subbutton_cancel}
                          onClick={() => {
                            resetForm(initialValues);
                            setShowSubProjectDocus(false);
                            resetNewSkill();
                            setSelectedRubro("");
                          }}
                        >
                          <span className={styles.trash}>
                            <Trash />
                          </span>
                          Cancelar
                        </button>
                        <button
                          className={styles.subbutton}
                          onClick={() => {
                            addHandler(values, initialValues, resetForm, navigate);
                            setShowSubProjectDocus(false);
                          }}
                        >
                          <span className={styles.subbutton_add}>
                            <Plus />
                          </span>
                          Agregar rubro a cotizar
                        </button>
                      </div>
                    </>
                )}
                  </div>
              </div>
          </Form>
        )}
    </Formik>
    </div >
  );
}; 

const mapStateToProps = (state) => {
  return {
    userId: state.general.userData.id,
    subProjectsType: state.profile.subProjectsType,
    subProjectId: state.profile.subProjectId,
    subProjectStyleForm: state.profile.styleForm,
    projectToEdit: state.profile.projectToEdit,
  };
};

export default connect(mapStateToProps, {
  setSendSubProject,
  postSubprojects,
  setProjectToEdit,
  closeMainModal,
  openEdifySpinner, 
  getSubprojectsType,
  resetNewSkill,
})(NuevoSubproyecto);
