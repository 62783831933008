import React, { useState } from "react";
import styles from "./PortfolioItemModal.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { Carousel } from 'nuka-carousel';
import EmptyState from "../../UI/EmptyState/EmptyState";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/arrowDownIcon.svg";
import PortfolioQuestion from "./PortfolioQuestion";
import { ReactComponent as CommentsIcon } from "../../../assets/svg/commentPost.svg";

const PortfolioItemModal = ({ item, closeModal, portfolioQuestionsForItem, myPerson, userData }) => {
    const [showQuestions, setShowQuestions] = useState(false);
    const [showQAndA, setShowQAndA] = useState(false);

    // Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const options = { day: 'numeric', month: 'short' };
        return utcDate.toLocaleDateString('es-ES', options);
    };
    const handleAskButton = () => {
        setShowQAndA(true);
    }


    return (
        <AnimatePresence >
            <div className={styles.modalContainer}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={styles.modal}
                >
                    <div className={styles.modalContent}>
                        <Carousel
                            showArrows={true}
                            wrapMode
                            slidesToShow={1}
                            className={styles.modalContent_carousel}
                        >
                            {item?.portfolio_images.map((image, index) => (
                                <img className={styles.modalContent_img} key={index} src={image.image} alt={item.title} />
                            ))}
                            <button className={styles.closeButton} onClick={closeModal}>
                                X
                            </button>
                            <p className={styles.modalContent_info_location}>{item?.zone}</p>
                            <p className={styles.modalContent_info_dates}>{formatDate(item?.startDate)} - {formatDate(item?.endDate)}</p>
                        </Carousel>
                        <div className={styles.modalContent_body}>
                            <div className={styles.modalContent_info}>
                                <div className={styles.modalContent_info_pill} />
                                <h2 className={styles.modalContent_info_title}>{item.name}</h2>
                                {!showQuestions && (
                                    <motion.div
                                        className={styles.modalContent_info_description}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {item.description}
                                    </motion.div>
                                )}
                            </div>
                            <div className={styles.modalContent_footer}>
                                <h4 className={styles.modalContent_footer_title}>
                                    Preguntas y respuestas:
                                    <ArrowDownIcon
                                        className={showQuestions ? styles.arrowUp : styles.arrowDown}
                                        width={20}
                                        height={20}
                                        title={showQuestions ? "Ocultar preguntas" : "Mostrar preguntas"}
                                        onClick={() => setShowQuestions(!showQuestions)} />
                                </h4>
                                <AnimatePresence>
                                    {showQuestions && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: "auto", transition: { duration: 0.3 } }}
                                            exit={{ opacity: 0, height: 0 }}
                                            className={styles.modalContent_footer_questions}
                                        >
                                            <div className={styles.modalContent_footer_questions_container}>
                                            {portfolioQuestionsForItem && portfolioQuestionsForItem.length > 0 ? (
                                                portfolioQuestionsForItem.map((question) => (
                                                    <div key={question.id} className={styles.modalContent_footer_question}>
                                                        <p className={styles.modalContent_footer_question_text}>{question.question}</p>

                                                        {question.answers && question.answers.length > 0 ? (
                                                            question.answers.map((answer) => (
                                                                <div key={answer.id} className={styles.modalContent_footer_answer}>
                                                                    <p className={styles.modalContent_footer_answer_text}>{answer.answer}</p>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className={styles.modalContent_footer_answer}>No hay respuestas aún.</p>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <EmptyState title="Nadie hizo preguntas ¡Hacé la primera!" icon={<CommentsIcon width={30} height={30} />} isPortfolio />
                                            )}
                                            </div>
                                            {!myPerson && 
                                            <button className={styles.modalContent_footer_button} onClick={handleAskButton}>Preguntar</button>
                                            }
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                </motion.div>

            </div>
            {showQAndA && <PortfolioQuestion closeModal={() => setShowQAndA(false)} portfolioId={item.id} userData={userData} />}
        </AnimatePresence>
    );
}

export default PortfolioItemModal;
