import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Feed from "../../components/Dashboard/Feed/Feed";
import AllProjectsSkeleton from "../../components/Dashboard/AllProjects/AllProjectsSkeleton/AllProjectsSkeleton";
import { connect } from "react-redux";
import { fireAnalytics } from "../../utils/firebase";
import {
  clearOnboardingForms,
  getProjectsByType,
  clearProjects,
  closeMainModal,
  setSubProjectStyle,
  resetProjectToEdit,
  openFeedTextModal,
} from "../../store/actions";
import Modal from "../../components/Modal/Modal";
import Botonera from "../../components/Botonera/Botonera";
import NewMaterialBudget from "../../components/NewMaterialBudget/NewMaterialBudget";
import NewProject from "../../components/NewProject/NewProject";
import QuePasaEnEdify from "../../components/QuePasaEnEdify/QuePasaEnEdify";
import FinancialData from "../../components/FinancialData/FinancialData";


const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
 `;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px 20px 10px;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const ProjectsPostulationsWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 313px;
    max-width: 313px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Dashboard = ({openFeedTextModal, ...props}) => {
  const [isOpenMaterialBudget, setIsOpenMaterialBudget] = useState(false);
  const [isOpenProject, setIsOpenProject] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);


  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  useEffect(() => {
    handleResize();
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    scrollToTop();

    return () => {
      window.removeEventListener('scroll', scrollToTop);
    };
  }, []);


  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fireAnalytics("dashboard", { category: "navigation" });
    clearOnboardingForms();
    props.clearProjects();
  }, []);

  const setModalOpenMaterialBudget = (isOpen) => {
    props.closeMainModal();
    props.setProjectFormStyle(false);
    props.setSubProjectStyle(false);
    props.resetProjectToEdit();
    setIsOpenMaterialBudget(isOpen);
  };

  const setModalOpenProject = (isOpen) => {
    props.closeMainModal();
    props.setProjectFormStyle(false);
    props.setSubProjectStyle(false);
    props.resetProjectToEdit();
    setIsOpenProject(isOpen);
  };

  return (
      <Container>
        <Wrapper >
        {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
            null}
          {props.isLoaded ? (
              <Feed/>
          ) : (
            <AllProjectsSkeleton />
          )}
        </Wrapper>
        <ProjectsPostulationsWrapper>
          <QuePasaEnEdify />
          <FinancialData />
        </ProjectsPostulationsWrapper>
        <Modal isOpen={isOpenMaterialBudget} setIsOpen={(newValue) => setModalOpenMaterialBudget(newValue)}>
          <NewMaterialBudget closeModal={() => setModalOpenMaterialBudget(false)}/>
        </Modal>
        <Modal
          isOpen={isOpenProject}
          setIsOpen={(newValue) => setModalOpenProject(newValue)}
        >
          <NewProject closeModal={() => setModalOpenProject(false)} />
        </Modal>
      </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    openVisualizer: state.general.recoverOpen,
    isLoaded: state.general.isLoaded,
  };
};

export default connect(mapStateToProps, {
  clearProjects,
  getProjectsByType,
  closeMainModal,
  setSubProjectStyle,
  resetProjectToEdit,
  openFeedTextModal,
})(Dashboard);
