import React, { useState } from 'react';
import styled from "styled-components";
import styles from "./CalculadoraDeMateriales.module.scss";
import { ReactComponent as RubrosIcon } from "../../../assets/svg/rubrosIcon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/svg/warningIcon.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  @media (max-width: 500px) {
    padding-top: 10px;
  }`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    width: 100%;
  @media (max-width: 900px) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }
`;

const CalculadoraDeMateriales = () => {
  const [selectedRubro, setSelectedRubro] = useState(null);

  return (
    <Container>
      <section className={styles.searchContainer}>
        <h1 className={styles.searchContainer_title}>Calculadora de Materiales</h1>
        <input type="text" className={styles.searchContainer_input} placeholder="Buscar material" />
      </section>
      <Wrapper>
        <main className={styles.bodyContainer}>
          <section className={styles.productCarousel}>
            <div className={styles.productCarousel_iconFixed}>
          <RubrosIcon width={25} height={25}/>
            </div>
            <div className={styles.productCarousel_itemContainer}>
              <div className={styles.productCarousel_itemContainer_info}>
                <div className={styles.productCarousel_itemContainer_info_title}>Aberturas de aluminio</div>
              </div>
            </div>
          </section>
          <section className={styles.productContainer}>
          {selectedRubro ? (
            <div className={styles.productContainer_itemContainer}>
              <div className={styles.productContainer_itemContainer_info}>
                Cemento
              </div>
            </div>
          ) : (
            <div className={styles.productContainer_itemContainerEmpty}>
              <div className={styles.productContainer_itemContainerEmpty_info}>
              Seleccionar un rubro de la lista para ver los materiales
              </div>    
            </div>
          )}
            <div className={styles.productContainer_resumeContainer}>
              <div className={styles.productContainer_printAndPublishButtons}>
                <button className={styles.productContainer_printAndPublishButtons_button}>Descargar/imprimir</button>
                <button className={styles.productContainer_printAndPublishButtons_button}>Publicar</button>
              </div>
              <div className={styles.productContainer_resumeContainer_item}>
                <div className={styles.productContainer_resumeContainer_item_title}>Cantidad</div>
                <div className={styles.productContainer_resumeContainer_item_value}>5</div>
              </div>
              <div className={styles.productContainer_resumeContainer_totalPricingContainer}>
                <div className={styles.productContainer_resumeContainer_totalPricingContainer_title}>Costo total</div>
                <div className={styles.productContainer_resumeContainer_totalPricingContainer_value}>$ 500</div>
                <div className={styles.productContainer_resumeContainer_totalPricingContainer_currency}>USD</div>
              </div>
              <div className={styles.productContainer_resumeContainer_warningContainer}>
                <WarningIcon width={20} height={20}/>
                <div className={styles.productContainer_resumeContainer_warningContainer_text}>Los precios pueden variar</div>
              </div>

            </div>
          </section>
        </main>
      </Wrapper>
    </Container>
  );
}

export default CalculadoraDeMateriales;