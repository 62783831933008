import React, { useState, useEffect, useRef } from "react";
import styles from "./DetailsForm.module.scss";
import { connect } from "react-redux";
import { ReactComponent as Name } from "../../../assets/svg/addPlaceholder.svg"
import { ReactComponent as DateIcon } from "../../../assets/svg/datePlaceholder.svg"
import { ReactComponent as Plus } from "../../../assets/svg/plus-icon.svg"
import { ReactComponent as Pencil } from "../../../assets/svg/pencilEditIcon.svg"
import Lottie from "react-lottie";
import errorData from "../../../assets/lotties/errorLogo.json";
import successData from "../../../assets/lotties/success.json";
import { ReactComponent as Location } from "../../../assets/svg/locationPlaceholder.svg"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { openMainModal, deleteImageFromProject } from "../../../store/actions";

const DetailsForm = ({
  values,
  formik,
  imageFile,
  fileName,
  fromNew,
  zone,
  setZone,
  isEditing,
  setChangeZone,
  projectForm,
  openMainModal,
  onImageFilesChange,
  image,
  minDate,
  imageEdit,
  imagesFromProject,
  setChangedImages,
  hidenForm,
  deleteImageFromProject,
  projectId,
  setMaxDateForSubProjects,
  setMinDateForSubProjects,
  ...props
}) => {
  let {
    nameEdit,
    descriptionEdit,
    endDateEdit,
    name,
    description,
    endDate,
    startDate,
    startDateEdit,
  } = values;

  const dateParts = endDate?.split('T')[0].split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  const formattedEndDate = `${day}/${month}/${year}`;
  const datePartsStart = startDate?.split('T')[0].split('-');
  const yearStart = datePartsStart[0];
  const monthStart = datePartsStart[1];
  const dayStart = datePartsStart[2];
  const formattedStartDate = `${dayStart}/${monthStart}/${yearStart}`;

  const [showEndDateEdit, setShowEndEdit] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isEmptyInEdit, setIsEmptyInEdit] = useState(false);
  const [city, setCity] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const inputRef = useRef(null);

  const handleChange = async (event) => {
    const value = event.target.value;

    setCity(value);
    setShowSuggestions(true);
    formik.setFieldValue('zone', value.trim());

    if (value.trim().length < 3) {
      setLoadingError(true)
    } else {
      setLoadingError(false)
    }

    if (value.trim().length >= 3) {
      setLoadingSuggestions(true);

      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${value}&format=json&limit=5&countrycodes=ar`);
        const data = await response.json();

        const citySuggestions = data.map(item => ({
          name: item.display_name,
          lat: item.lat,
          lon: item.lon
        }));
        if (citySuggestions.length > 0) {
          setSuggestions(citySuggestions);
        } else {
          setSuggestions([{name: "No hemos encontrado ninguna sugerencia para tu búsqueda"}]);
        }

      } catch (error) {
        console.error('Error al realizar la solicitud a la API:', error);
        setShowSuggestions(false);
      } finally {
        setLoadingSuggestions(false);
      }
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }
  , []);

  const handleSelect = (city) => {
    setCity(city);
    formik.setFieldValue('zone', city);
    setShowSuggestions(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      formik.setFieldValue('zone', city);
      setShowSuggestions(false);
    }
  }

  //Maneja el estado del contenedor de imagenes
  useEffect(() => {
    if (imageUrls.length === 0 && imagenes.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [imageUrls, imagenes]);

  useEffect(() => {
    if (isEditing && imagesFromProject.length === 0 && imageUrls.length === 0) {
      setIsEmptyInEdit(true);
    } else {
      setIsEmptyInEdit(false);
    }
  }, [imagesFromProject, isEditing, imageUrls]);


  useEffect(() => {
    if (isEditing) {
      const imagesFromEdit = imagesFromProject.map((image) => ({
        image: image.image,
        id: image.id,
      })
      );
      setImageUrls(imagesFromEdit);
      setImagenes(imagesFromEdit);
    }
  }, [imagesFromProject, isEditing]);

  const imageChangeHandler = (event) => {
    const files = event.target.files;
    const updatedFiles = Array.from(files);
    if (updatedFiles.length + (imagenes.length || (imagesFromProject?.length || 0)) > 5) {
      openMainModal({
        title: "No se pueden agregar más de 5 imágenes",
        body: "Por favor, elimine alguna de las imágenes existentes ¡Muchas gracias!",
        isSuccess: true,
      });
      return;
    }

    // Leer el contenido de los archivos y generar las URLs de imagen
    updatedFiles.forEach((file) => {
      const updatedImageUrls = [];
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result;
        // Agregar la nueva imagen al array de imágenes
        setImagenes((prevImagenes) => [
          ...prevImagenes,
          imageUrl,
        ]);
        setImageUrls((prevImageUrls) => [
          ...prevImageUrls,
          ...updatedImageUrls,
          imageUrl,
        ]);
        onImageFilesChange((prevFiles) => [...prevFiles, file]);
        setChangedImages(true);
      };
      reader.readAsDataURL(file);
    });
  };


  //Funcion para quitar las imagenes antes de subirlas
  const handleImageDelete = (index, id) => {
    const updatedImages = [...imagenes];
    updatedImages.splice(index, 1);
    setImagenes(updatedImages);

    const updatedUrls = [...imageUrls];
    updatedUrls.splice(index, 1);
    setImageUrls(updatedUrls);

    if (isEditing && imagesFromProject.length > 0) {
      deleteImageFromProject(id, projectId);

    }
  };

  //Funcion que emula el comportamiento del input de carga de imagenes
  const handleImageUploadButtonClick = () => {
    const uploadInput = document.getElementById('image');
    if (uploadInput) {
      uploadInput.click();
    }
  };

  return (
    <div className={styles.generalContainer}>
      <div className={styles.imageContainer}>
        <section onClick={handleImageUploadButtonClick} className={`${styles.carouselContainer} ${isEmpty && !isEditing ? ` ${styles.carouselContainer_empty}` : ''}`}>
          <Carousel
            className={hidenForm ? styles.mainContainer_carousel_hidden : styles.mainContainer_carousel}
            selectedItem={currentImageIndex}
            onChange={(index) => setCurrentImageIndex(index)}
            showThumbs={false}
          >
            {isEditing && imagesFromProject.length > 0 ? (
              imagesFromProject.map((image, index) => (
                <div key={index} className={styles.customImg_container}>
                  <img src={image.image} alt="" className={styles.customImg} />
                </div>
              ))
            ) : (
              imageUrls.map((imageUrl, index) => (
                <div key={index} className={styles.customImg_container}>
                  <img src={imageUrl} alt="" className={styles.customImg} />
                </div>
              ))
            )}
          </Carousel>
          {isEmptyInEdit ? (
            <div className={styles.emptyContainer}>
              <span className={styles.emptyContainer_text}>No hay imágenes en tu proyecto</span>
              <Plus width={20} height={20} />
            </div>
          ) : null}
          {isEmpty && !isEditing ? (
            <div className={styles.emptyContainer}>
              <span className={styles.emptyContainer_text}>Click para adjuntar imagenes</span>
              <Plus width={20} height={20} />
            </div>
          ) : null}
        </section>
        <div className={`${styles.itemInfo_containerThumb} ${isEditing ? "" : styles.flex}`}>
          {imagenes && imagenes.length > 0 ? (
            imagenes.map((file, index) =>
            (
              <div key={index}>
                <div className={styles.itemInfo_imageContainer}>
                  <div className={styles.itemInfo_imageContainer_thumb} >
                    <span className={styles.itemInfo_imageCount}>{index + 1}</span>
                    <img src={isEditing ? (imagesFromProject.length > 0 ? file.image : file) : imageUrls[index]} alt="" className={styles.itemInfo_image} />
                    <button onClick={() => handleImageDelete(index, file.id)} className={styles.deleteButton}>
                      X
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.itemInfo_imageContainer}>
              {isEditing ? (
                <p className={styles.itemInfo_imageContainer_empty}>Podés agregar hasta {5 - imagesFromProject.length} imágenes al proyecto</p>
              ) : (
                <p className={styles.itemInfo_imageContainer_empty}>Podés agregar hasta 5 imágenes al proyecto</p>
              )}
            </div>
          )}
          {imagenes && imagenes.length < 5 && imagenes.length > 0 ? (
            <div className={styles.itemInfo_addMore} onClick={handleImageUploadButtonClick}>
              <Plus width={20} height={20} />
            </div>
          ) : null}
        </div>
        <>
          <label
            htmlFor="image"
            className={styles.itemInfo_imageMask}
          ></label>
          <input
            className={styles.itemInfo_inputHidden}
            type="file"
            name="image"
            id="image"
            onChange={imageChangeHandler}
            style={{ display: "none" }}
            accept="image/*"
            multiple
          />
        </>
      </div>
      <div className={styles.infoContainer}>
        <div className={`${styles.itemInfo} ${styles.detail}`}>
          <span className={styles.ProjectName}>NOMBRE DEL PROYECTO</span>
          <div className={styles.relative}>
            <div className={styles.absolute}><Name /></div>
            <input
              type="text"
              id="name"
              name="name"
              value={name || formik.values.name}
              className={`${styles.itemInfo_input} ${formik.errors.name && styles.error} ${formik.values.name !== "" && !isEditing && styles.success}`}
              placeholder="Ej.: Torre Saint Muratz"
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
            {formik.errors.name && (
              <div className={styles.errorContainer}>
                <Lottie
                  options={{
                    animationData: errorData,
                    ...defaultOptions,
                  }}
                  height={30}
                  width={30}
                />
              </div>
            )}
            {formik.values.name !== "" &&  !isEditing && (
              <div className={styles.errorContainer}>
                <Lottie
                  options={{
                    animationData: successData,
                    ...defaultOptions,
                  }}
                  height={30}
                  width={30}
                />
              </div>
            )}
          </div>
          <div>
          </div>
          <div className={`${styles.itemInfo} ${styles.detail}`}>
            <span className={styles.ProjectName}>UBICACIÓN</span>
            <div className={styles.relative} ref={inputRef}>
              <div className={styles.absolute}><Location /></div>
              <input
                type="text"
                id="zone"
                name="zone"
                value={city || formik.values.zone}
                className={`${styles.itemInfo_input} ${formik.errors.zone &&  styles.error} ${formik.values.zone !== "" &&  !isEditing && styles.success}`}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Escribe el nombre de la ciudad..."
              />
              {showSuggestions && (
                <ul className={styles.cityOptionList}>
                  {loadingError && (
                    <li className={styles.cityOptionList_item}>Por favor, ingrese al menos 3 caracteres</li>
                  )}
                  {loadingSuggestions ? (
                    <li className={styles.cityOptionList_item}>Cargando sugerencias...</li>
                  ) : (
                    suggestions.map((suggestion, index) => (
                      <li key={index} onClick={() => handleSelect(suggestion.name)} className={styles.cityOptionList_item}>
                        {suggestion.name}
                      </li>
                    ))
                  )}
                </ul>
              )}
              {formik.errors.zone && (
                <div className={styles.errorContainer}>
                  <Lottie
                    options={{
                      animationData: errorData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>
              )}
              {formik.values.zone !== "" && !isEditing && (
                <div className={styles.errorContainer}>
                  <Lottie
                    options={{
                      animationData: successData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.itemInfo} ${styles.detail}`}>
            <span className={styles.ProjectName}>INICIO DE LA PUBLICACIÓN</span>
            <div className={styles.relative}>
              <div className={styles.absolute}><DateIcon /></div>
              {!formik.touched.startDate && isEditing ? (
                <input
                  type="text"
                  id={`startDate${!fromNew ? "Edit" : ""}`}
                  name={`startDate${!fromNew ? "Edit" : ""}`}
                  value={formattedStartDate || startDateEdit}
                  className={styles.itemInfo_input}
                />
              ) : (

                <input
                  type="date"
                  id={`startDate${!fromNew ? "Edit" : ""}`}
                  name={`startDate${!fromNew ? "Edit" : ""}`}
                  value={formik.values.startDate}
                  className={`${styles.itemInfo_input} ${formik.errors.startDate &&  styles.error} ${formik.values.startDate !== "" &&  !isEditing && styles.success}`}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setMinDateForSubProjects(e.target.value);
                  }}
                  min={minDate}
                />)}
              {formik.errors.startDate && (
                <div className={styles.errorContainer}>
                  <Lottie
                    options={{
                      animationData: errorData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>
              )}
              {formik.values.startDate !== "" && !isEditing && (
                <div className={styles.errorContainer}>
                  <Lottie
                    options={{
                      animationData: successData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
            <span className={styles.ProjectName}>FINALIZACIÓN DE LA PUBLICACIÓN</span>
            <div className={styles.relative}>
              <div className={styles.absolute}><DateIcon /></div>
              {!formik.touched.endDate && isEditing ? (
                <input
                  type="text"
                  id={`endDate${!fromNew ? "Edit" : ""}`}
                  name={`endDate${!fromNew ? "Edit" : ""}`}
                  value={formattedEndDate || endDateEdit}
                  className={styles.itemInfo_input}
                />
              ) : (

                <input
                  type="date"
                  id={`endDate${!fromNew ? "Edit" : ""}`}
                  name={`endDate${!fromNew ? "Edit" : ""}`}
                  value={formik.values.endDate}
                  className={`${styles.itemInfo_input} ${formik.errors.endDate &&  styles.error} ${formik.values.endDate !== "" && !isEditing && styles.success}`}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setMaxDateForSubProjects(e.target.value);
                  }}
                />)}
              {formik.errors.endDate && (
                <div className={styles.errorContainer}>
                  <Lottie
                    options={{
                      animationData: errorData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>
              )}
              {formik.values.endDate !== "" && !isEditing ? (
                <div className={styles.errorContainer}>
                  <Lottie
                    options={{
                      animationData: successData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.addDescription}>DESCRIPCION GENERAL</div>
          <div className={styles.addDescription_itemInfo}>
            <Pencil height={15} width={15} className={styles.addDescription_absolute} />
            <textarea
              id="description"
              name="description"
              value={description || formik.values.description}
              placeholder="Añade una breve descripción."
              className={`${styles.itemInfo_area} ${formik.errors.description && styles.error} ${formik.values.description !== "" && formik.touched.description && !isEditing ? styles.success : ""} `}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
            {formik.errors.description &&  (
              <div className={styles.errorContainer_description}>
                <Lottie
                  options={{
                    animationData: errorData,
                    ...defaultOptions,
                  }}
                  height={30}
                  width={30}
                />
              </div>)}
            {formik.values.description !== "" && !isEditing ? (
              <div className={styles.errorContainer_description}>
                <Lottie
                  options={{
                    animationData: successData,
                    ...defaultOptions,
                  }}
                  height={30}
                  width={30}
                />
              </div>
            ) : null}
          </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projectForm: state.profile.projectForm,
  };
};

export default connect(mapStateToProps, {
  openMainModal,
  deleteImageFromProject,
})(DetailsForm);


