import * as actionType from "../actions/types";

const INITIAL_STATE = {
  name: "",
  mail: "",
  type: "",
  file: false, //este dato se puso false y no null, por que genera un error en formik en el onboarding paso 2
  images: [],
  imagesPreview: [],
  personaForm: {
    name: "",
    lastName: "",
    dniCuil: "",
    profession: "",
    tel: "",
    address: "",
    webSite: "",
  },
  empresaForm: {
    cuit: "",
    companyName: "",
    field: "",
    tel: "",
    address: "",
    webSite: "",
  },
  cities: [],
  codeValidation: false,
  usersBySkill: [],
  searchSkill: [],
  skillsList: [],
  totalPages: 0,
  totalSkillsListed: 0,
  selectedSkill: [],
  usersSkillList: [],
  skillContent: [],
  userSkills: [],
  userPortfolios: [],
  portfolios: [],
  openEdit: false,
  portfolioId: null,
  workBagUsers: [],
  currentWorkOffer: {},
  workOfferPostulation: false,
  portfolioEdit: {
    image: null,
    id: null,
    name: "",
    description: "",
    startDate: "",
    endDate: "",
  },
  allJobRequests: [],
  plans: [],
  userPlan: {},
  planID: null,
  terminosStatus: false,
  errorMessage: "",
};

const editPortfolioImage = (state, image) => {
  let IndextoChange = state.images.findIndex((item) => item.id === image.id);
  state.images.splice(IndextoChange, 1, { id: image.id, image: image.image });
  state.imagesPreview.splice(IndextoChange, 1, {
    id: image.id,
    image: image.image64,
  });
  let newImageArray = [].concat(state.images);
  let newPreviewArray = [].concat(state.imagesPreview);
  return { ...state, images: newImageArray, imagesPreview: newPreviewArray };
};

const editOldPortfolioImage = (state, image) => {
  let IndextoChange = image.id;
  let newImageId = state.portfolioEdit.images[IndextoChange].id;
  state.imagesPreview.splice(IndextoChange, 1, {
    id: state.portfolioEdit.images[IndextoChange].id,
    image: image.image64,
  });
  let newImageArray = [...state.images, { id: newImageId, image: image.image }];
  newImageArray.sort(function (a, b) {
    return a.id - b.id;
  });
  let newImagePreviewArray = [].concat(state.imagesPreview);
  return {
    ...state,
    images: newImageArray,
    imagesPreview: newImagePreviewArray,
  };
};

const deleteImageReducerEditType = (state, image) => {
  let newPreviewArray = state.imagesPreview.filter(
    (preview) => preview.id !== image
  );
  let newImageArray = state.images.filter((img) => img.id !== image);
  return { ...state, imagesPreview: newPreviewArray, images: newImageArray };
};

const clearUserSkills = (state) => {
  return {
    ...state,
    userSkills: [],
  };
};

const addSkillToUser = (state, skillId) => {
  let skill = state.skills.find((element) => {
    return element.id === skillId;
  });
  let add = state.userSkills.find((elSkill) => {
    return elSkill.id === skill.id;
  });
  if (!add) {
    return {
      ...state,
      userSkills: state.userSkills.concat(skill),
    };
  } else {
    return {
      ...state,
    };
  }
};

const removeUserSkill = (state, skillId) => {
  let userSkill = state.userSkills.filter((element) => {
    return element.id !== skillId;
  });

  return {
    ...state,
    userSkills: userSkill,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.REGISTER_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        mail: action.payload.mail,
      };
    case actionType.VALIDATION_SUCCESS:
      return {
        ...state,
        codeValidation: action.payload,
      };
    case actionType.CHANGE_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case actionType.SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case actionType.SET_PORTFOLIO:
      return {
        ...state,
        portfolios: action.payload,
      };
    case actionType.ADD_PORTFOLIO:
      return {
        ...state,
        portfolios: state.portfolios.concat(action.payload),
      };
    case actionType.UPLOAD_IMAGE_PORTFOLIO:
      return {
        ...state,
        images: state.images.concat(action.payload),
      };
    case actionType.UPLOAD_IMAGE_PREVIEW: {
      return {
        ...state,
        imagesPreview: state.imagesPreview.concat(action.payload),
      };
    }
    case actionType.GET_CITIES: {
      return {
        ...state,
        cities: action.payload,
      };
    }
    case actionType.GET_ALL_JOB_REQUESTS: 
    return {
      ...state,
      allJobRequests: action.payload,
    };
    case actionType.CURRENT_WORK_OFFER: {
      return {
        ...state,
        currentWorkOffer: action.payload,
      };
    }
    case actionType.GET_ALL_WORKBAG_USERS: {
      return {
        ...state,
        workBagUsers: action.payload,
      };
    }
    case actionType.SEND_WORK_OFFER_POSTULATION: {
      return {
        ...state,
        workOfferPostulation: action.payload,
      };
    }
    case actionType.FILTER_WORKBAG_USERS: {
      return {
        ...state,
        workBagUsers: action.payload,
      };
    }
    case actionType.DELETE_IMAGE_BUFFER: {
      return {
        ...state,
        images: INITIAL_STATE.images,
        imagesPreview: INITIAL_STATE.imagesPreview,
      };
    }
    case actionType.EDIT_IMAGE_NEW_PORTFOLIO: {
      return editPortfolioImage({ ...state }, action.payload);
    }
    case actionType.EDIT_IMAGE_OLD_PORTFOLIO: {
      return editOldPortfolioImage({ ...state }, action.payload);
    }
    case actionType.DELETE_IMAGE_PORTFOLIO_EDIT: {
      return deleteImageReducerEditType({ ...state }, action.payload);
    }
    case actionType.DELETE_IMAGE_PORTFOLIO_NEW: {
      return deleteImageReducerEditType({ ...state }, action.payload);
    }
    case actionType.GET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case actionType.SEARCH_SKILL:
      return {
        ...state,
        searchSkill: action.payload,
      };
    case actionType.GET_USER_SKILL:
      return {
        ...state,
        userSkills: action.payload,
      };
    case actionType.SET_SKILLS:
      return {
        ...state,
        skills: action.payload,
        skillContent: action.payload,
      };
    case actionType.SET_SKILLS_LIST:
      return {
        ...state,
        skillsList: action.payload,
      };
    case actionType.SET_USERS_BY_SKILL:
      return {
        ...state,
        usersBySkill: action.payload.users,
        selectedSkill: action.payload.selectedSkill,
      };
    case actionType.RESET_SELECTEDSKILL:
      return {
        ...state,
        selectedSkill: [],
      };
    case actionType.SET_USERS_IN_SKILL_NAME:
      return {
        ...state,
        usersSkillList: action.payload.userList,
        totalPages: action.payload.totalPages,
        totalSkillsListed: action.payload.totalSkillsListed,
      };
    case actionType.CLEAR_USER_SKILLS:
      return clearUserSkills({ ...state });
    case actionType.ADD_SKILL:
      return addSkillToUser({ ...state }, action.payload);
    case actionType.REMOVE_SKILL:
      return removeUserSkill({ ...state }, action.payload);
    case actionType.GET_USER_PLAN:
      return {
        ...state,
        userPlan: action.payload,
      };
    case actionType.SET_TERMINOS:
      return {
        ...state,
        terminosStatus: !state.terminosStatus,
      };
    case actionType.SET_PLANID:
      return {
        ...state,
        planID: action.payload,
      };
    case actionType.REGISTER_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case actionType.SET_PERSONA_FORM:
      return {
        ...state,
        personaForm: action.payload,
        file: action.file,
      };
    case actionType.SET_EMPRESA_FORM:
      return {
        ...state,
        empresaForm: action.payload,
        file: action.file,
      };
    case actionType.CLEAR_ONBOARDING_FORM:
      return {
        ...state,
        empresaForm: INITIAL_STATE.empresaForm,
        personaForm: INITIAL_STATE.personaForm,
        file: INITIAL_STATE.file,
      };
    default:
      return state;
  }
};
