import * as actionTypes from "../actions/types";
import React from "react";
import axios from "../../axios";
import axiosAuth from "../../axiosAuth";
import { getProfileVisualizer } from "./dashboardActions";
import { activateFreeTrial, viewAccessRequest } from "./profileActions";
import { openMainModal } from "./";
import { fireAnalytics } from "../../utils/firebase";
import Button from "../../components/UI/Forms/Button/Button";

const setAccess = (values) => {
  return {
    type: actionTypes.SET_ACCESS,
    payload: values,
  };
};

export const navStyle = (value) => {
  return {
    type: actionTypes.NAV_STYLE,
    payload: value,
  };
};

export const openFilterUsersModal = (value) => {
  return {
    type: actionTypes.OPEN_FILTER_USERS_MODAL,
    payload: value,
  };
};

export const closeFilterUsersModal = () => {
  return {
    type: actionTypes.CLOSE_FILTER_USERS_MODAL,
  };
};

export const userInfoNavStyle = (value) => {
  return {
    type: actionTypes.USER_INFO_NAV_STYLE,
    payload: value,
  }
}

export const validationSuccess = (action) => {
  return {
    type: actionTypes.VALIDATION_SUCCESS,
    payload: action,
  };
};

export const startLoading = () => {
  return { type: actionTypes.SKELETON_START };
};
export const endLoading = () => {
  return { type: actionTypes.SKELETON_END };
};

export const setErrorMessage = (value) => {
  return {
    type: actionTypes.REGISTER_FAIL,
    payload: value,
  };
};

export const openSendContactNotificationModal = (value) => {
  return {
    type: actionTypes.OPEN_SEND_CONTACT_NOTIFICATION_MODAL,
    payload: {
      isOpen: true,
      userData: value,
    },
  };
};

export const closeSendContactNotificationModal = () => {
  return {
    type: actionTypes.CLOSE_SEND_CONTACT_NOTIFICATION_MODAL,
    payload: { isOpen: false, userData: null },
  };
};

export const openCalificationModal = (value) => {
  return {
    type: actionTypes.OPEN_CALIFICATION_MODAL,
    payload: {
      isOpen: true,
      userData: value,
    },
  };
};

export const closeCalificationModal = () => {
  return {
    type: actionTypes.CLOSE_CALIFICATION_MODAL,
    payload: { isOpen: false, userData: null },
  };
};

export const setLoginAnimation = (value) => {
  return {
    type: actionTypes.SET_LOGIN_ANIMATION,
    payload: value,
  };
};
export const setSuccessFalse = () => {
  return {
    type: actionTypes.RECOVERY_START,
  };
};

export const loginWithGoogle = (credentials, navigate) => {
  const token = credentials;

  return (dispatch) => {
    axiosAuth
      .get(`/google/login?token=${token}`)
      .then((response) => {
        /* Tomamos los valores de la respuesta y los almacenamos */
        let { access_token, refresh_token, expires_in, name } = response.data;
        /* Despachamos un creador de acciones con los datos para almacenarlos en el reducer  */
        dispatch(
          setAccess({
            access: `Bearer ${access_token}`,
            refresh: `Bearer ${refresh_token}`,
            expire: expires_in,
            name: name,
          })
        );
        /* Guardamos los Token en el LocalStorage */
        localStorage.setItem("access-Token-Edify", access_token);
        localStorage.setItem("access-RefreshToken-Edify", refresh_token);
        /* Login Success trigerea el auth a true, nos logeamos correctamente */
        dispatch({ type: actionTypes.LOGIN_SUCCESS });
        /* Configuramos el header del get */
        let getUserHeader = {
          headers: { Authorization: `Bearer ${access_token}` },
        };
        /* Pedimos los datos al servidor con la configuracion */
        localStorage.removeItem("toValidateUsername");
        localStorage.removeItem("fromLogin");
        axiosAuth.get("/user/data", getUserHeader).then((res) => {
          dispatch(viewAccessRequest());
          dispatch({ type: actionTypes.GET_DATA, payload: res.data });
          if (
            (res.data.name && res.data.last_name) ||
            (res.data.rubro && res.data.razon_social)
          ) {
            dispatch(setLoginAnimation(true));
            navigate("/dashboard"); //example, reubicar a perfil.
          } else if (res.data.type === "") {
            navigate("/select-type"); //example, reubicar a select Type.
          } else {
            navigate("/select-type");
          }
          dispatch({ type: actionTypes.LOGIN_END });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loginUserAction = (value, navigate) => {
  /* Creamos una instancia de FormData con los datos del login */
  const formData = {
    username: value.email,
    password: value.password,
  };

  /* Creamos un objeto config que contenga la configuracion del header */
  const config = {
    headers: { Authorization: "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0" },
  };
  const emailComparision =
    value.email === localStorage.getItem("EmailRegister");
  return (dispatch) => {
    /* Ejecutamos un loader */
    dispatch({ type: actionTypes.LOGIN_START });
    /* Request con los datos del login y la configuracion */
    axiosAuth
      .post("/oauth/token", formData, config)
      .then((response) => {
        /* Tomamos los valores de la respuesta y los almacenamos */
        let { access_token, refresh_token, expires_in, name } = response.data;
        /* Despachamos un creador de acciones con los datos para almacenarlos en el reducer  */
        dispatch(
          setAccess({
            access: `Bearer ${access_token}`,
            refresh: `Bearer ${refresh_token}`,
            expire: expires_in,
            name: name,
          })
        );
        /* Guardamos los Token en el LocalStorage */
        localStorage.setItem("access-Token-Edify", access_token);
        localStorage.setItem("access-RefreshToken-Edify", refresh_token);
        /* Login Success trigerea el auth a true, nos logeamos correctamente */
        dispatch({ type: actionTypes.LOGIN_SUCCESS });
        /* Configuramos el header del get */
        let getUserHeader = {
          headers: { Authorization: `Bearer ${access_token}` },
        };
        /* Pedimos los datos al servidor con la configuracion */
        localStorage.removeItem("toValidateUsername");
        localStorage.removeItem("fromLogin");
        if (emailComparision) localStorage.removeItem("EmailRegister");
        axiosAuth.get("/user/data", getUserHeader).then((res) => {
          dispatch(viewAccessRequest());
          dispatch({ type: actionTypes.GET_DATA, payload: res.data });
          if (res.data.type === null) {
            navigate("/select-type"); //example, reubicar a select Type.
          } if
            (
            (res.data.name && res.data.last_name && res.data.type !== null) ||
            (res.data.rubro && res.data.razon_social && res.data.type !== null)
          ) {
            dispatch(setLoginAnimation(true));
            navigate("/dashboard"); //example, reubicar a perfil.
          } else {
            navigate("/select-type");
          }
          if (res.data.free_trial?.status === "no-activated") {
            dispatch(activateFreeTrial());
            dispatch(loadUserData());
          }
          dispatch({ type: actionTypes.LOGIN_END });
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body:
              error?.response?.data?.message === "Password is wrong" ? (
                "La contraseña es incorrecta"
              ) : error?.response?.data?.message === "User not found" ? (
                <>
                  <p>
                    Mail no registrado, por favor comience el proceso de
                    registro
                  </p>
                  <br></br>
                  <Button
                    typebtn="link"
                    link="register"
                    bordered="true"
                    children="Registrarme"
                    onClick={() =>
                      fireAnalytics("dsh_create_project", {
                        category: "dashboard",
                      })
                    }
                  />{" "}
                </>
              ) : (
                error?.response?.data?.error_description
              ),
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch({ type: actionTypes.LOGIN_FAIL, payload: true });
      });
  };
};

export const recoverPasswordAction = (value) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RECOVERY_START });

    axiosAuth({
      method: "post",
      url: "/user/passwordRecovery",
      params: {
        username: value.email,
      },
      headers: {
        Authorization: "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0",
      },
    })
      .then((response) => {
        dispatch({ type: actionTypes.RECOVERY_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.RECOVERY_FAIL });
        if (error.response.data.message.includes("User no encontrado")) {
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: "Parece que no existe ningun usuario con ese email.",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        } else {
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: "Usuario no registrado",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      });
  };
};

export const setNewPasswordRecovery = (value) => {
  return (dispatch) => {
    axiosAuth({
      method: "post",
      url: "/user/data/password/recover",
      data: {
        password: value.values.password,
        token: value.token,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        fireAnalytics("reset_password", { category: "authentication" });
        fireAnalytics("password_recovery_confirm", { category: "navigation" });
        dispatch({ type: actionTypes.RECOVERY_SUCCESS });
      })
      .catch((error) => {
        if (error.response.data.message.includes("expired")) {
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: "Tu token expiró. Por favor volvé a completar el formulario de Olvidé mi contraseaña",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        } else {
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: error.response.data.message,
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      });
  };
};

// Registro de usuario nuevo
export const registerNewUser = (newUser, navigate) => {
  const formData = new FormData();
  formData.append("name", newUser.firstName);
  formData.append("last_name", newUser.lastName);
  formData.append("password", newUser.password);
  formData.append("username", newUser.email);
  formData.append("sendConfirmation", newUser.confirmationMethod === "sendSms" ? "sms" : "email")
  formData.append("phone", newUser.phone);
  localStorage.setItem("welcomeName", newUser.firstName);

  return (dispatch) => {
    dispatch({ type: actionTypes.REGISTER_START });
    axiosAuth
      .post("/user/create", formData)
      .then((res) => {
        dispatch({
          type: actionTypes.REGISTER_SUCCESS,
          payload: { name: newUser.firstName, mail: newUser.email },
        });
        localStorage.setItem("EmailRegister", newUser.email);
        localStorage.setItem("passRegister", newUser.password);
        navigate("/welcome");
      })
      .catch((error) => {
        dispatch(
          setErrorMessage(
            error.response.data.message ===
              `User already exist with email: ${newUser.email}`
              ? "Al parecer este correo ya fue utilizado. Prueba con otro, o revisa tu casilla para verificar tu cuenta."
              : error.response.data.message
          )
        );
      });
  };
};

//Envía código de verificación recibido
export const sendValidationCode = (code, navigate) => {
  const formData = {
    email: localStorage.getItem("EmailRegister"),
    code: code
  }

  return (dispatch) => {
    axiosAuth
      .post("/user/validate-validation-code", formData)
      .then((res) => {
        if (res.data === "La cuenta ha sido validada con exito") {
          setTimeout(() => {
            dispatch({ type: actionTypes.VALIDATION_SUCCESS, payload: true });
          }, 2000)
        } else {
          dispatch({ type: actionTypes.VALIDATION_SUCCESS, payload: false });
          setTimeout(() => {
            dispatch(
              openMainModal({
                title: "¡Error!",
                body: "El código ingresado es incorrecto. Por favor, intenta nuevamente.",
                isSuccess: true,
                confirmHandler: null,
              })
            );
          }, 3000)
        }
      }
      )
      .catch((error) => {
        setTimeout(() => {
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: "El código ingresado es incorrecto. Por favor, intenta nuevamente.",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }, 3000)
      });
  };
};

export const resendMailUserConfirmation = (values) => {

  const body = {
    username: values.newEmail !== '' ? values.newEmail : values.email,
    phone: values.confirmationMethod === "sendSms" ? values.phone : "email",
    sendBy: values.confirmationMethod === "sendSms" ? "phone" : "email"
  }

  return (dispatch) => {
    axios
      .post("/user/resend-validation-code", body,
        {
          headers: {
            Authorization: "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0",
          },
        })
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Éxito!",
            body: `Hemos reenviado el código de validación a ${values.confirmationMethod === "sendSms" ? "tu celular." : "tu casilla de email."}`,
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((error) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No hemos podido reenviar el código de validación a tu casilla de email. Por favor, intente nuevamente o escribanos a contacto@edify.la ¡Muchas Gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      }
      );
  }
}


export const loadUserData = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios.get("/user/data", bearerToken)
      .then((res) => {
        dispatch({ type: actionTypes.GET_DATA, payload: res.data });
        if (res.data.type === "PERSON") {
          const {
            dniCuit,
            name,
            lastName,
            profession,
            phone,
            address,
            urlSiteWeb,
            password,
          } = res.data;
          dispatch({
            type: actionTypes.SET_PERSONA_DATA,
            payload: {
              dniCuit,
              name,
              lastName,
              profession,
              phone,
              address,
              urlSiteWeb,
              password,
            },
          });
        }
        dispatch({ type: actionTypes.LOGIN_SUCCESS });
        dispatch({ type: actionTypes.LOGIN_END });
      })
      .catch((error) => {
        console.error("Error loading user data:", error);
      });
  };
};

export const openAction = (value) => {
  return (dispatch) => {
    dispatch(getProfileVisualizer(value));
  };
};

export const goToProjectId = (value, navigate) => {
  return () => {
    navigate(`/obrasprivadas?id=${value}`);
  };
};

export const closeAction = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
  };
};

export const openPortfolioAction = () => {
  fireAnalytics("ob_step_3_addportfolio", { category: "onboarding" });
  window.scrollTo({ top: 0, behavior: "smooth" });
  return {
    type: actionTypes.OPEN_PORTFOLIO_ADD,
  };
};
export const closePortfolioAction = () => {
  return {
    type: actionTypes.CLOSE_PORTFOLIO_ADD,
  };
};

export const logoutAction = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const setLoginType = (value) => {
  return {
    type: actionTypes.LOGIN_TYPE,
    payload: value,
  };
};

function removeItems() {
  localStorage.removeItem("access-RefreshToken-Edify");
  localStorage.removeItem("access-Token-Edify");
  localStorage.removeItem("saveForm");
}
export const logout = (hasnavigate, navigate) => {
  if (hasnavigate) {
    return (dispatch) => {
      removeItems();
      dispatch(logoutAction());
      navigate("/");
    };
  } else {
    removeItems();
    logoutAction();
  }
};

export const loginGuestAccount = () => {
  return async (dispatch) => {
    if (localStorage.getItem("access-Token-Edify")) {
      return;
    } else {
      return axiosAuth
        .get("/oauth/token/invited")
        .then((body) => {
          const access_token = body.data.access_token;
          const authHeaders = {
            headers: { Authorization: `Bearer ${access_token}` },
          };
          localStorage.setItem("access-Token-Edify", access_token);
          localStorage.setItem("access-RefreshToken-Edify", access_token);
          dispatch({ type: actionTypes.LOGIN_SUCCESS });
          return axiosAuth.get("/user/data", authHeaders).then((res) => {
            dispatch({ type: actionTypes.GET_DATA, payload: res.data });
            return;
          });
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };
};

export const logoutFromGuestAccount = (navigate) => {
  return (dispatch) => {
    removeItems();
    dispatch(logoutAction());
    navigate("/register");
  };
};
