import React from "react";
import styles from "./ToolTip.module.scss";

const Tooltip = ({ text }) => {
    return (
        <div className={styles.tooltip}>
            {text}
        </div>
    );
};

export default Tooltip;