import React, { useState, useEffect } from "react";
import styles from "./FilterQuotation.module.scss";
import { filterQuotations, getSubquotationType } from "../../../store/actions";
import { ReactComponent as InfoIcon } from "../../../assets/svg/infoIcon.svg";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { connect } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/svg/arrowUpIcon.svg";
import { motion } from "framer-motion";

const FilterQuotation = ({
    filterQuotations,
    setIsFiltered,
    setIsAdjudicated,
    listaDeRubros,
    getSubquotationType,
    page,
    resultados,
    setIsLoading,
    totalDeProyectosMostrados,
    ...props }) => {
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Publicas");
    const [selectedOptionOrganism, setSelectedOptionOrganism] = useState({id: 0, name: "Todos"});
    const [selectedOptionJurisdiction, setSelectedOptionJurisdiction] = useState({id : 0, name : "Todas"});
    const [showWorkTypeOptions, setShowWorkTypeOptions] = useState(false);
    const [showCurrencyOptions, setShowCurrencyOptions] = useState(false);
    const [showAmountOptions, setShowAmountOptions] = useState(false);
    const [showDateOptions, setShowDateOptions] = useState(false);
    const [showExactDateInput, setShowExactDateInput] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [selectedWorkType, setSelectedWorkType] = useState({id: 0, name: "Todas"});
    const [selectedCurrency, setSelectedCurrency] = useState("Pesos");
    const [minAmount, setMinAmount] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [asociatedWords, setAsociatedWords] = useState("");
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [selectedSort, setSelectedSort] = useState("Recientes");
    const [dateFilterHeader, setDateFilterHeader] = useState("Fecha de apertura");
    const [isFilteredQuotation, setIsFilteredQuotation] = useState(false);


    //Funcion que maneja el click en los filtros
    const handleFilterClick = (filterName) => {
        if (selectedFilter === filterName) {
            setSelectedFilter(null);
        } else {
            setSelectedFilter(filterName);
        }
    };

    //Maneja el el estado del título del filtro por fecha y los valores del rango de monto
    useEffect(() => {
        if (selectedCurrency === "Dólares") {
            setMinAmount("");
            setMaxAmount("");
        } else {
            setMinAmount("");
            setMaxAmount("");
        }
        if (selectedOption === "Privadas") {
            setDateFilterHeader("Fecha de cierre");
        } else {
            setDateFilterHeader("Fecha de apertura");
        }
    }, [selectedOption, selectedCurrency]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setSelectedFilter(null);
    };

    useEffect(() => {
        getSubquotationType();
    }, []);

    const handleOptionSelectOrganism = (option, id) => {
        setSelectedOptionOrganism({ name: option, id: id });
        setSelectedFilter(null);
    };

    const handleOptionSelectJurisdiction = (option, id) => {
        setSelectedOptionJurisdiction({ name: option, id: id });
        setSelectedFilter(null);
    };


    //Formateo la fecha a mostrar
    const formatDate = (date) => {
        const dateArray = date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        return `${day}/${month}/${year}`;
    };

    //Formateo el monto a mostrar
    const formatAmount = (amount) => {
        const numericAmount = parseInt(amount, 10);
        if (!isNaN(numericAmount)) {
            return numericAmount.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0,
            });
        }
        return amount;
    };

    //Generamos rangos para busqueda por fecha
    const generateDateRange = (dateRange) => {
        const today = new Date();
        const thirtyDaysFromNow = new Date(today);
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
        const sixtyDaysFromNow = new Date(today);
        sixtyDaysFromNow.setDate(sixtyDaysFromNow.getDate() + 60);
        const ninetyDaysFromNow = new Date(today);
        ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);

        switch (dateRange) {
            case "Próximos 30 días":
                return [today, thirtyDaysFromNow];
            case "30 a 60 días":
                return [thirtyDaysFromNow, sixtyDaysFromNow];
            case "60 a 90 días":
                return [sixtyDaysFromNow, ninetyDaysFromNow];
            default:
                return null;
        }
    };


    //Funcion para filtrar los proyectos 
    const handleFilter = (hasFilter) => {
        setIsLoading(true)
        setIsFiltered(hasFilter);
        setIsFilteredQuotation(true);

        //Maneja el cambio de pagina
        const pageToSend = hasFilter ? 1 : page;

        //Formatea la fecha para enviar
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        //Traemos la fecha de hoy
        const todayDate = new Date();
        const today = formatDate(todayDate);

        //Generamos el rango de fechas
        const dateRange = generateDateRange(selectedDateRange);
        const dateFrom = selectedDate !== null ? today : dateRange ? formatDate(new Date(dateRange[0])) : null;
        const dateTo = selectedDate !== null ? selectedDate : dateRange ? formatDate(new Date(dateRange[1])) : null;


        const filter = {
            sort: selectedSort === "Recientes" ? "DESC" : "ASC",
            asociatedWords: asociatedWords,
            zone: null,
            minAmount: minAmount,
            maxAmount: maxAmount,
            currency: selectedCurrency === "Dólares" ? "USD" : selectedOption === "Publicas" ? "$" : "Pesos Arg",
            jurisdiction: selectedOptionJurisdiction === "Todas" ? null : selectedOptionJurisdiction?.id,
            organism: selectedOptionOrganism === "Todos" ? null : selectedOptionOrganism?.id,
            workType: selectedWorkType === "Todas" ? null : selectedWorkType?.id,
            dateRange: { dateFrom, dateTo },
            projectType: selectedOption,
        }

        filterQuotations(filter, pageToSend, 10);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }

    const handleReset = () => {
        if (selectedOption === "Privadas") {
            const filter = {
                sort: "DESC",
                asociatedWords: "",
                zone: null,
                minAmount: "",
                maxAmount: "",
                currency: "Pesos Arg",
                jurisdiction: null,
                organism: null,
                workType: null,
                dateRange: { dateFrom: null, dateTo: null },
                projectType: "Privadas",
            }
            filterQuotations(filter, 1, 10);
            setSelectedOption("Privadas");
            setSelectedWorkType({ id: null, name: null });
            setSelectedCurrency("Pesos");
            setSelectedDate(null);
            setSelectedDateRange(null);
            setAsociatedWords("");
            setSelectedSort("Recientes");
            setSelectedFilter(null);
            setSelectedOptionJurisdiction("Todas");
        } else {
            if (selectedOption === "Publicas") {
                const filter = {
                    sort: "DESC",
                    asociatedWords: "",
                    zone: null,
                    minAmount: "",
                    maxAmount: "",
                    currency: "$",
                    jurisdiction: null,
                    organism: null,
                    workType: null,
                    dateRange: { dateFrom: null, dateTo: null },
                    projectType: "Publicas",
                }
                filterQuotations(filter, 1, 10);
                setSelectedOption("Publicas");
                setSelectedWorkType({ id: null, name: null });
                setSelectedCurrency("Pesos");
                setSelectedDate(null);
                setSelectedDateRange(null);
                setAsociatedWords("");
                setSelectedSort("Recientes");
                setSelectedFilter(null);
                setSelectedOptionJurisdiction("Todas");
            }
        }
    }

    //Definimos el array de jurisdicciones
    const jurisdictionArray = [
        { id: 0, name: "Todas" },
        { id: 1, name: "Buenos Aires" },
        { id: 2, name: "Catamarca" },
        { id: 3, name: "Chaco" },
        { id: 4, name: "Chubut" },
        { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
        { id: 6, name: "Córdoba" },
        { id: 7, name: "Corrientes" },
        { id: 8, name: "Entre Ríos" },
        { id: 9, name: "Formosa" },
        { id: 10, name: "Jujuy" },
        { id: 11, name: "La Pampa" },
        { id: 12, name: "La Rioja" },
        { id: 13, name: "Mendoza" },
        { id: 14, name: "Misiones" },
        { id: 15, name: "Neuquén" },
        { id: 16, name: "Río Negro" },
        { id: 17, name: "Salta" },
        { id: 18, name: "San Juan" },
        { id: 19, name: "San Luis" },
        { id: 20, name: "Santa Cruz" },
        { id: 21, name: "Santa Fe" },
        { id: 22, name: "Santiago del Estero" },
        { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
        { id: 24, name: "Tucumán" },
    ]

    //Definimos el array de tipo de obra
    const typeOfWorkArray = [
        { id: 0, name: "Todas" },
        { id: 1, name: "Obras Viales" },
        { id: 2, name: "Obras eléctricas" },
        { id: 3, name: "Obras de gas" },
        { id: 4, name: "Obras Sanitarias / Saneamiento" },
        { id: 5, name: "Obras de Arquitectura (Nuevas)" },
        { id: 6, name: "Obras de Arquitectura (Remodelaciones)" },
        { id: 7, name: "Servicios profesionales" },
        { id: 8, name: "Servicios de consultoría" },
        { id: 9, name: "Servicios varios" },
        { id: 10, name: "Obras menores y Pequeñas" },
        { id: 11, name: "Obras Hidráulicas" },
        { id: 12, name: "Materiales de Construcción" },
        { id: 13, name: "Maquinarias y Herramientas" },
        { id: 14, name: "Otras Obras" },
    ]

    return (
        <div className={styles.filterContainer}>
            <div className={styles.mainFilter}>
                <div
                    className={styles.mainFilter_projectType}
                    onClick={() => handleFilterClick('projectType')}
                >
                    <div className={`${styles.mainFilter_projectType_infoContainer} ${selectedFilter === 'projectType' ? styles.selectedFilter : ''}`} >
                        <div className={styles.mainFilter_projectType_itemContent}>
                            <p className={styles.mainFilter_projectType_title}>
                                Tipo de solicitud de cotización
                            </p>
                            <div className={styles.mainFilter_projectType_info}>
                                <p className={styles.mainFilter_projectType_info_p}>
                                {selectedOption !== null ? selectedOption : 'Todas'}</p>
                                {selectedOption === 'Publicas' && (
                                    <span className={styles.filterContainer_searchType_referenceContainer_spanPublic}></span>
                                )}
                                {selectedOption === 'Privadas' && (
                                    <span className={styles.filterContainer_searchType_referenceContainer_spanPrivate}></span>
                                )}
                            </div>
                        </div>
                        <div className={styles.mainFilter_projectType_infoIcon}>
                            <InfoIcon width={20} height={20} />
                        </div>
                    </div>
                    {selectedFilter === 'projectType' && (
                        <div className={styles.mainFilter_projectType_options}>
                            <div
                                className={styles.mainFilter_projectType_options_item}
                                onClick={() => handleOptionSelect('Publicas')}
                            >
                                Pública

                            </div>
                            <div
                                className={styles.mainFilter_projectType_options_item}
                                onClick={() => { handleOptionSelect('Privadas') }}
                            >
                                Privada
                            </div>
                        </div>
                    )}
                </div>
                {(selectedOption === "Publicas" || selectedOption === "Todas") && (
                    <div className={styles.mainFilter_organism} onClick={() => handleFilterClick('organism')}>
                        <div className={`${styles.mainFilter_organism_infoContainer} ${selectedFilter === 'organism' ? styles.selectedFilter : ''}`}>
                            <div className={styles.mainFilter_organism_itemContent}>
                                <p className={styles.mainFilter_organism_title}>
                                    Tipo de organismo
                                </p>
                                <p className={styles.mainFilter_organism_info}>
                                    {selectedOptionOrganism.name === null || selectedOptionOrganism.name === undefined ? 'Todos' : selectedOptionOrganism.name}
                                </p>
                            </div>
                            <div className={styles.mainFilter_organism_infoIcon}>
                                <InfoIcon width={20} height={20} />
                            </div>
                        </div>
                        {selectedFilter === 'organism' && (
                            <div className={styles.mainFilter_organism_options}>
                                <div className={styles.mainFilter_organism_options_item} onClick={() => handleOptionSelectOrganism('Todos', 0)}>Todos</div>
                                <div className={styles.mainFilter_organism_options_item} onClick={() => handleOptionSelectOrganism('Nacional', 1)}>Nacional</div>
                                <div className={styles.mainFilter_organism_options_item} onClick={() => handleOptionSelectOrganism('Provincial', 2)}>Provincial</div>
                                <div className={styles.mainFilter_organism_options_item} onClick={() => handleOptionSelectOrganism('Municipal', 3)}>Municipal</div>
                                <div className={styles.mainFilter_organism_options_item} onClick={() => handleOptionSelectOrganism('Empresa Pública', 4)}>Empresa Pública</div>
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.mainFilter_jurisdiction} onClick={() => handleFilterClick('jurisdiction')}>
                    <div className={`${styles.mainFilter_jurisdiction_infoContainer} ${selectedFilter === 'jurisdiction' ? styles.selectedFilter : ''}`}>
                        <div className={styles.mainFilter_jurisdiction_itemContent}>
                            <p className={styles.mainFilter_jurisdiction_title}>
                                Geolocalización
                            </p>
                            <p className={styles.mainFilter_jurisdiction_info}>
                                {selectedOptionJurisdiction.name === null || selectedOptionJurisdiction.name === undefined ? "Todas" : selectedOptionJurisdiction.name}
                            </p>
                        </div>
                        <div className={styles.mainFilter_jurisdiction_infoIcon}>
                            <InfoIcon width={20} height={20} />
                        </div>
                    </div>
                    {selectedFilter === 'jurisdiction' && (
                        <>
                            <div className={styles.mainFilter_jurisdiction_options}>
                                {jurisdictionArray.map((jurisdiction) => (
                                    <div className={styles.mainFilter_jurisdiction_options_item} key={jurisdiction.id} onClick={() => handleOptionSelectJurisdiction(jurisdiction.name, jurisdiction.id)}>{jurisdiction.name}</div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className={`${styles.mainFilter_button} ${selectedOption === "Privadas" ? styles.searchButtonPrivate : ""}`} onClick={() => handleFilter(true)}>
                    <p className={`${styles.mainFilter_button_title} ${selectedOption === "Privadas" ? styles.title : ""}`}>Buscar</p>
                </div>
            </div>
            <div className={styles.filterContainer_searchType} >
                <div className={styles.filterContainer_searchType_referenceContainer}>
                    <span className={styles.filterContainer_searchType_referenceContainer_spanPublicMini}></span>
                    <p className={styles.filterContainer_searchType_referenceContainer_txtPublic}>Públicas</p>
                    <span className={styles.filterContainer_searchType_referenceContainer_spanPrivateMini}></span>
                    <p className={styles.filterContainer_searchType_referenceContainer_txt}>Privadas</p>
                </div>
                <p className={styles.filterContainer_searchType_txt} onClick={() => setAdvancedSearch(!advancedSearch)}>
                    {advancedSearch ? <ArrowUpIcon width={15} height={15} /> : <ArrowDownIcon width={15} height={15} />}
                    Búsqueda avanzada</p>
            </div>
            {advancedSearch && (
                <motion.div initial={{ y: -10 }} animate={{ y: 0 }} className={styles.advancedFilter}>
                    <div className={styles.advancedFilter_filterWorkType}
                        onMouseEnter={() => setShowWorkTypeOptions(true)}
                        onMouseLeave={() => setShowWorkTypeOptions(false)}>
                        <h4 className={styles.advancedFilter_filterWorkType_header}>{selectedOption === "Privadas" ? "Rubro" : "Tipo de obra"}</h4>
                        <div className={styles.advancedFilter_filterWorkType_options}>
                            <div className={styles.advancedFilter_filterWorkType_options_placeHolder}>
                                <p className={styles.advancedFilter_filterWorkType_options_placeHolder_txt}>{selectedWorkType.name ? selectedWorkType.name : "Elegir"}</p>
                                <div className={styles.advancedFilter_filterWorkType_options_iconContainer}>
                                    {showWorkTypeOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                </div>
                            </div>
                            {showWorkTypeOptions && (
                                <div className={styles.advancedFilter_filterWorkType_options_menu}>
                                    {selectedOption === "Privadas" ? listaDeRubros.map((listaDeRubros) => (
                                        <div className={styles.advancedFilter_filterWorkType_options_item} onClick={() => { setSelectedWorkType({ name: listaDeRubros.name, id: listaDeRubros.id }); setShowWorkTypeOptions(false) }} key={listaDeRubros.id}>{listaDeRubros.name}</div>
                                    )) : typeOfWorkArray.map((typeOfWork) => (
                                        <div className={styles.advancedFilter_filterWorkType_options_item} onClick={() => { setSelectedWorkType({ name: typeOfWork.name, id: typeOfWork.id }); setShowWorkTypeOptions(false) }} key={typeOfWork.id}>{typeOfWork.name}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.advancedFilter_filterCurrency}
                        onMouseEnter={() => setShowCurrencyOptions(true)}
                        onMouseLeave={() => setShowCurrencyOptions(false)}>
                        <h4 className={styles.advancedFilter_filterCurrency_header}>Moneda</h4>
                        <div className={styles.advancedFilter_filterCurrency_options}>
                            <div className={styles.advancedFilter_filterCurrency_options_placeHolder}>
                                <p className={styles.advancedFilter_filterCurrency_options_placeHolder_txt}>{selectedCurrency !== "Pesos" ? selectedCurrency : "Pesos"}</p>
                                <div className={styles.advancedFilter_filterCurrency_options_iconContainer}>
                                    {showCurrencyOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                </div>
                            </div>
                            {showCurrencyOptions && (
                                <div className={styles.advancedFilter_filterCurrency_options_menu}>
                                    <div className={styles.advancedFilter_filterCurrency_options_item} onClick={() => { setSelectedCurrency("Pesos"); setShowCurrencyOptions(false) }}>Pesos</div>
                                    <div className={styles.advancedFilter_filterCurrency_options_item} onClick={() => { setSelectedCurrency("Dólares"); setShowCurrencyOptions(false) }}>Dólares</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.advancedFilter_filterAmount}
                        onMouseEnter={() => setShowAmountOptions(true)}
                        onMouseLeave={() => setShowAmountOptions(false)}>
                        <h4 className={styles.advancedFilter_filterAmount_header}>{selectedOption === "Privadas" ? "Presupuesto estimado" : "Monto"}</h4>
                        <div className={styles.advancedFilter_filterAmount_options}>
                            <div className={styles.advancedFilter_filterAmount_options_placeHolder}>
                                <p className={styles.advancedFilter_filterAmount_options_placeHolder_txt}>{maxAmount > 0 ? `Desde ${formatAmount(minAmount)} Hasta ${formatAmount(maxAmount)}` : "$0 - $500.000.000 +"}</p>
                                <div className={styles.advancedFilter_filterAmount_options_iconContainer}>
                                    {showAmountOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                </div>
                            </div>
                            {showAmountOptions && (
                                <div className={styles.advancedFilter_filterAmount_options_menu}>
                                    <div className={styles.advancedFilter_filterAmount_options_container}>
                                        <div className={styles.advancedFilter_filterAmount_options_header}>
                                            Elegí un rango
                                        </div>
                                        <div className={styles.advancedFilter_filterAmount_options_headerSub}>
                                            Monto mínimo
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max={selectedCurrency === "Pesos" ? "50000000000" : "50000000"}
                                            step={selectedCurrency === "Pesos" ? "1000000" : "1000"}
                                            value={minAmount}
                                            onChange={(e) => {
                                                const newMinAmount = Math.min(parseInt(e.target.value), maxAmount);
                                                setMinAmount(newMinAmount);
                                            }}
                                            className={styles.advancedFilter_filterAmount_options_slider}
                                        />
                                        <div className={styles.advancedFilter_filterAmount_options_headerSub}>
                                            Monto máximo
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max={selectedCurrency === "Pesos" ? "50000000000" : "50000000"}
                                            step={selectedCurrency === "Pesos" ? "1000000" : "1000"}
                                            value={maxAmount}
                                            onChange={(e) => {
                                                const newMaxAmount = Math.max(parseInt(e.target.value), minAmount);
                                                setMaxAmount(newMaxAmount);
                                            }}
                                            className={styles.advancedFilter_filterAmount_options_slider}
                                        />
                                        <div className={styles.advancedFilter_filterAmount_options_item} onClick={() => setShowAmountOptions(false)}>
                                            <p className={styles.advancedFilter_filterAmount_options_item_number}>
                                                {formatAmount(minAmount)} - {formatAmount(maxAmount)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.advancedFilter_filterDate}
                        onMouseEnter={() => setShowDateOptions(true)}
                        onMouseLeave={() => { setShowDateOptions(false); setShowExactDateInput(false) }}>
                        <h4 className={styles.advancedFilter_filterDate_header}>{dateFilterHeader}</h4>
                        <div className={styles.advancedFilter_filterDate_options}>
                            <div className={styles.advancedFilter_filterDate_options_placeHolder}>
                                <p className={styles.advancedFilter_filterDate_options_placeHolder_txt}>{selectedDateRange || selectedDate ? selectedDateRange || formatDate(selectedDate) : "Elegir"}</p>
                                <div className={styles.advancedFilter_filterDate_options_iconContainer}>
                                    {showDateOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                </div>
                            </div>
                            {showDateOptions && (
                                <div className={styles.advancedFilter_filterDate_options_menu}>
                                    <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("Próximos 30 días"); setShowDateOptions(false) }}>Proximos 30 días</div>
                                    <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("30 a 60 días"); setShowDateOptions(false) }}>30 a 60 días</div>
                                    <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("60 a 90 días"); setShowDateOptions(false) }}>60 a 90 días</div>
                                    <div
                                        className={styles.advancedFilter_filterDate_options_item}
                                        onClick={() => { setShowExactDateInput(true); setSelectedDateRange(null) }}
                                    >
                                        Fecha exacta
                                    </div>
                                    {showExactDateInput && (
                                        <input
                                            type="date"
                                            value={selectedDate}
                                            onChange={(e) => setSelectedDate(e.target.value)}
                                            className={styles.advancedFilter_filterDate_options_item} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.advancedFilter_filterAsociatedWords}>
                        <h4 className={styles.advancedFilter_filterAsociatedWords_header}>Palabras clave</h4>
                        <div className={styles.advancedFilter_filterAsociatedWords_options}>
                            <input
                                type="text"
                                value={asociatedWords}
                                onChange={(e) => setAsociatedWords(e.target.value)}
                                className={styles.advancedFilter_filterAsociatedWords_options_input} />
                        </div>
                    </div>
                </motion.div>
            )}
            <div className={styles.filterContainer_searchInfo}>
                <div className={styles.filterContainer_searchInfo_sortContainer}>
                    <div className={styles.filterContainer_searchInfo_sort_txt}>Ordenar por:</div>
                    <div className={styles.filterContainer_searchInfo_sort}>
                        <div className={styles.filterContainer_searchInfo_sort_selected}
                            onMouseEnter={() => setShowSortOptions(true)}
                            onMouseLeave={() => setShowSortOptions(false)}>
                            <p className={styles.filterContainer_searchInfo_sort_selected_txt}>{selectedSort === "Antiguos" ? selectedSort : "Recientes"}</p>
                            <div className={styles.filterContainer_searchInfo_sort_selected_iconContainer}>
                                {showSortOptions ? <ArrowDown width={20} height={25} /> : <ArrowUp width={20} height={25} />}
                            </div>
                            {showSortOptions && (
                                <div className={styles.filterContainer_searchInfo_sort_options}>
                                    <p className={styles.filterContainer_searchInfo_sort_options_item}
                                        onClick={() => { setSelectedSort("Recientes"); setShowSortOptions(false) }}>Recientes</p>
                                    <p className={styles.filterContainer_searchInfo_sort_options_item}
                                        onClick={() => { setSelectedSort("Antiguos"); setShowSortOptions(false) }}>Antiguos</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.filterContainer_searchInfo_txtContainer}>
                    <p className={styles.filterContainer_searchInfo_txt}>Se encontraron {resultados} resultados</p>
                    {resultados > 10 && (
                        <p className={styles.filterContainer_searchInfo_txt}>Mostrando {totalDeProyectosMostrados} de {resultados}</p>
                    )}
                </div>
                <div className={styles.filterContainer_searchInfo_txtContainer_clearFilter}>
                    <p className={styles.filterContainer_searchInfo_txtContainer_clearFilter_txt} onClick={() => handleReset()}>Limpiar filtros</p>
                </div>
            </div>
            <div className={styles.filterContainer_sortInfo}>
                <ul className={`${styles.filterContainer_sortInfo_list} ${selectedOption === "Privadas" ? styles.sortQuotation : ""}`}>
                    <li className={styles.filterContainer_sortInfo_list_item}>Geolocalización</li>
                    <li className={styles.filterContainer_sortInfo_list_item}> Nombre</li>
                    <li className={styles.filterContainer_sortInfo_list_item}>{selectedOption === "Privadas" && isFilteredQuotation ? "Rubro" : "Tipo de obra"}</li>
                    {selectedOption !== "Privadas" && (
                        <li className={styles.filterContainer_sortInfo_list_item}>Tipo de organismo</li>)}
                    <li className={styles.filterContainer_sortInfo_list_item}>{selectedOption === "Privadas" ? "Fecha de inicio" : "Comitente"} </li>
                    <li className={styles.filterContainer_sortInfo_list_item}>{selectedOption === "Privadas" ? "Fecha de finalización" : "Fecha de apertura"}</li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Ciudad</li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Moneda</li>
                    {selectedOption !== "Privadas" && (
                        <li className={styles.filterContainer_sortInfo_list_item}>Monto</li>)}
                    <li className={styles.filterContainer_sortInfo_list_item}>Acciones</li>
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        resultados: state.profile.itemCount,
        totalDeProyectosMostrados: state.profile.quotations.entities?.length,
        listaDeRubros: state.profile.subQuotations,
    };
};

export default connect(mapStateToProps, {
    filterQuotations,
    getSubquotationType,
})(FilterQuotation);

