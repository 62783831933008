import React, { useState, useEffect } from "react";
import styles from "./Profesionales.module.scss";
import { connect } from "react-redux";
import {
  getUsersRegisteredData,
  setGoBackToCategoryList,
  getAllUsersBySkills,
  getUsersBySkillName,
  openFilterUsersModal,
  closeFilterUsersModal,
} from "../../store/actions";
import Botonera from "../../components/Botonera/Botonera";
import RegisteredUsersCategory from "../../components/RegisteredUsers/RegisteredUsersCategory";
import { ReactComponent as FilterIcon } from "../../assets/svg/filterControlsIcon.svg";
import FondoComunidadEdify from "../../assets/images/fondoComunidadEdify1.jpg";
import FilterUsersModal from "../../components/UI/FilterUsersModal/FilterUsersModal";
import RegisteredUserItem from "../../components/RegisteredUsers/RegisteredUserItem";
import { ReactComponent as ProfileIcon } from "../../assets/svg/profileIcon.svg";


const Profesionales = ({
  usersRegisterData,
  getAllUsersBySkills,
  getUsersBySkillName,
  getUsersRegisteredData,
  setGoBackToCategoryList,
  completeSkillsList,
  selectedSkill,
  edifySpinner,
  filterUsersModal,
  openFilterUsersModal,
  closeFilterUsersModal,
  ...props }) => {

  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSkillsList, setSelectedSkillsList] = useState([]);
  const [initialState, setInitialState] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [noResultMessage, setNoResultMessage] = useState("");
  const [checkedItems, setCheckedItems] = useState({});

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };


  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    getUsersRegisteredData();
    handleResize();
    getAllUsersBySkills();

  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const groupUsersBySkillNameAndCompletedFields = (users) => {
    const groupedUsers = {};
    users.forEach(user => {
      if (user.skill) {
        user.skill.forEach(skill => {
          const skillName = skill.skill.name;
          if (!groupedUsers[skillName]) {
            groupedUsers[skillName] = [];
          }
          groupedUsers[skillName].push(user);
        });
      }
    });

    return groupedUsers;
  };

  const groupedUsersBySkillAndCompletedFields = groupUsersBySkillNameAndCompletedFields(usersRegisterData);

  // Ordenar las categorías según el porcentaje de usuarios con portfolios
  const sortedCategories = Object.entries(groupedUsersBySkillAndCompletedFields).sort(([_, usersA], [__, usersB]) => {
    const ratioA = usersA.filter(user => user.portfolio && user.portfolio.length > 0).length / usersA.length;
    const ratioB = usersB.filter(user => user.portfolio && user.portfolio.length > 0).length / usersB.length;
    return ratioB - ratioA;
  });

  const handleSkillsToggle = (skillName) => {

    const updatedSelectedSkillsList = selectedSkillsList.includes(skillName)
      ? selectedSkillsList.filter((skill) => skill !== skillName)
      : [...selectedSkillsList, skillName];

    setSelectedSkillsList(updatedSelectedSkillsList);
    getUsersBySkillName(updatedSelectedSkillsList);

    if (updatedSelectedSkillsList.length === 0) {
      setInitialState(false);
      setGoBackToCategoryList(false);
    }
  
  // Update checkedItems state
   setCheckedItems((prevCheckedItems) => {
  const updatedCheckedItems = { ...prevCheckedItems };
  updatedSelectedSkillsList.forEach(skill => {
    updatedCheckedItems[skill] = true;
  });
  // Uncheck items that are no longer in the selectedSkillsList
  Object.keys(updatedCheckedItems).forEach(skill => {
    if (!updatedSelectedSkillsList.includes(skill)) {
      updatedCheckedItems[skill] = false;
    }
  });
  return updatedCheckedItems;
});
};

  const handleSearchClick = (searchValue) => {
    setInitialState(true)
    setShowInfo(true);
    handleSkillsToggle(searchValue.toLowerCase());
    setSuggestions([]);
  };

  //Funciones del input 

  const normalizarAcentos = (str) => { 
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleInputChange = (e) => {
    const search = e.target.value.toLowerCase();
    generateSuggestions(search);
    setInputValue(search);

    if (search === "") {
      setNoResultMessage("");
    }

    if (e.key === "Enter") {
      if (suggestions.length > 0) {
        const matchingSuggestion = suggestions.find(suggestion =>  normalizarAcentos(suggestion.name.toLowerCase()) === search);

        if (matchingSuggestion) {
          handleSearchClick(matchingSuggestion.name);
          setInputValue("");
          setNoResultMessage("");
        } else {
          setSuggestions([]);
          setNoResultMessage("La búsqueda no coincide con ninguna sugerencia");
        }
      } else {
        if (search !== "") {
          setNoResultMessage("No se encontraron resultados");
        }
      }
    }
  };

  const generateSuggestions = (search) => {
    if (search === "") {
      setSuggestions([]);
    } else {
      const newSuggestions = completeSkillsList.filter((skill) =>
        normalizarAcentos(skill.name.toLowerCase()).startsWith(search)
      );
      setSuggestions(newSuggestions);
    };
  };

  const resetCheckboxState = () => {
    setCheckedItems({});
  };

  const handleMakeSearch = () => {
    if (selectedSkillsList.length > 0) {
      getUsersBySkillName(selectedSkillsList);
      setInitialState(true)
      closeFilterUsersModal()
      resetCheckboxState();
    } else {
      if (selectedSkillsList.length === 0 && selectedSkill.length === 0) {
        setInitialState(false)
      }
    }
  }

  //Asegura no mostrar usuarios repetidos
  const displayedUsers = new Set();

  return (
    <main className={styles.generalContainer}>
      {ocultarEnMobile &&
        <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.headerContainer_imgContainer}>
            <img className={styles.headerContainer_imgContainer_img} src={FondoComunidadEdify} alt={"img"} />
          </div>
          <div className={styles.headerContainer_searchInput}>
            <input
              className={styles.headerContainer_searchInput_input}
              type="text"
              placeholder="Buscar profesional "
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputChange} />
            {noResultMessage !== "" && (
              <ul className={styles.suggestions}>
                <li className={styles.suggestions_item}>
                  <div className={styles.suggestions_item_container}>
                    <p className={styles.suggestions_item_name}>
                      {noResultMessage}
                    </p>
                  </div>
                </li>
              </ul>
            )}
            {Array.isArray(suggestions) && suggestions.length > 0 && (
              <ul className={styles.suggestions}>
                {suggestions.map((suggestion) => (
                  <li className={styles.suggestions_item} key={suggestion.name}>
                    <div className={styles.suggestions_item_container} onClick={() => handleSearchClick(suggestion.name)}>
                      <p className={styles.suggestions_item_name}>
                        {suggestion.name}
                        ({suggestion.users})
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <div onClick={() => openFilterUsersModal()} className={styles.headerContainer_searchInput_button}>
              <FilterIcon width={15} height={15} />
              {selectedSkillsList.length > 0 && (
                <span className={styles.headerContainer_searchInput_button_count}>{selectedSkillsList.length}</span>
              )}            
            </div>
          </div>
        </div>
        {selectedSkillsList.length > 0 ? (
          <div className={styles.selectedSkillsContainer}>
            {selectedSkillsList.map((skill) => (
              <div key={skill} className={styles.selectedSkillsContainer_skill}>
                <p className={styles.selectedSkillsContainer_skill_text}>{skill}</p>
                <div className={styles.selectedSkillsContainer_skill_close} onClick={() => handleSkillsToggle(skill)}>
                  <span className={styles.selectedSkillsContainer_skill_close_text}>x</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
        <div className={styles.toolTipContainer}>
          <p className={styles.toolTipContainer_text}>¿Tenés un proyecto en mente? Encontrá al profesional indicado, contactalo y sumalo a tu equipo.</p>
        </div>
        )} 
        <div className={styles.allCategoriesContainer}>
          {initialState ? (
            <div className={styles.infoContainer}>
              <RegisteredUsersCategory selectedSkillsList={selectedSkillsList} />
            </div>
          ) : (
            <>
            <header className={styles.buttonsContainer_header}>
                <h2 className={styles.buttonsContainer_header_title}><ProfileIcon height={12} width={12}/>Profesionales destacados</h2>
              </header>
            <div className={styles.buttonsContainer}>           
              {sortedCategories.map(([profession, usersArray]) => (
                usersArray.map(user => {
                  if (!displayedUsers.has(user.id)) {
                    displayedUsers.add(user.id);

                    return (
                      <div key={user.id} className={styles.categoryContainer}>
                        <div className={styles.categoryContainer_title}>
                          <h2 className={styles.categoryContainer_title_txt}>{profession}</h2>
                        </div>
                        <div className={styles.categoryContainer_users}>
                          <RegisteredUserItem key={user.id} user={user} completedFields={user.completedFields} stars={user.stars} portfolio={user.portfolio}/>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ))}
            </div>
             </>
          )}      
        </div>
      </div>
      {filterUsersModal && (
        <FilterUsersModal
          setShowInfo={setShowInfo}
          setIsLoading={setIsLoading}
          setSelectedSkillsList={setSelectedSkillsList}
          selectedSkillsList={selectedSkillsList}
          initialState={initialState}
          setInitialState={setInitialState}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          handleMakeSearch={handleMakeSearch}
          resetCheckboxState={resetCheckboxState}
        />
      )}
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    usersRegisterData: state.general.usersRegisteredData,
    userData: state.general.userData,
    edifySpinner: state.general.edifySpinner,
    filterUsersModal: state.general.filterUsersModal,
    completeSkillsList: state.register.skillsList,
    selectedSkill: state.register.selectedSkill,
  }
};

export default connect(mapStateToProps, {
  getUsersRegisteredData,
  getAllUsersBySkills,
  getUsersBySkillName,
  setGoBackToCategoryList,
  openFilterUsersModal,
  closeFilterUsersModal,
})(Profesionales);
