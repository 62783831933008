import React from "react";
import styles from "./LogOutMenu.module.scss";
import { connect } from "react-redux";
import { closeMainModal, logout, openMainModal } from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogOutIcon } from "../../../assets/svg/logOutIcon.svg";

const LogOutMenu = ({openMainModal, closeMainModal, userData, ...props}) => {
 const navigate = useNavigate();

  const handleCloseSesion = () => {
    openMainModal({
      title: "¿Deseas cerrar la sesión activa?",
      body: "Dejaran de llegarte las notificaciones de actividad.",
      isSuccess: false,
      confirmHandler: () => {props.logout(true, navigate); closeMainModal()},
    });
  };

  return (
    <div className={styles.navContainer} >
      <div className={styles.menuItem} onClick={() => handleCloseSesion()}>
      <LogOutIcon width={30} height={30} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    active: state.general.compActive,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, { logout, openMainModal, closeMainModal })(LogOutMenu);
