import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    getPrivateProjectById,
    openTutorialModal,
    getPostulationProjectStatus,
    validateAccessRequest,
    openNDAModal,
    getPostulations,
    setPostulationProjectId,
    getSubProjectBudgets,
    setProjectToEdit,
    deleteProyecto,
    getProjectQuestions,
} from "../../../../store/actions";
import Modal from "../../../../components/Modal/Modal";
import Maps from "../../../../components/UI/Maps/Maps";
import NewProject from "../../../../components/NewProject/NewProject";
import Botonera from "../../../Botonera/Botonera";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ProyectoId.module.scss";
import { ReactComponent as FacebookIcon } from "../../../../assets/svg/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/svg/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/svg/LinkedIN.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/svg/twitterSvg.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/svg/whatsAppIcon.svg";
import { ReactComponent as CommentsIcon } from "../../../../assets/svg/commentPost.svg";
import ProjectQuestions from "./ProjectQuestions";
import ProjectAnswer from "./ProjectAnswer";
import { Carousel } from 'nuka-carousel';
import "./NukaCarousel.css";
import EmptyState from "../../../UI/EmptyState/EmptyStateComments";
import ProyectoIdHeader from "./ProyectoIdHeader";
import ProyectoSkeletonLoader from "./ProjectSkeleton";


const ProyectoId = ({
    setProjectLoading,
    getPrivateProjectById,
    getPostulationProjectStatus,
    getProjectQuestions,
    postulacionesDelUsuario,
    postulacionesDeProyecto,
    projectQuestions,
    getSubProjectBudgets,
    proyecto,
    openTutorialModal,
    openNDAModal,
    validateAccessRequest,
    getPostulations,
    setProjectToEdit,
    setPostulationProjectId,
    deleteProyecto,
    budgets,
    userData,
    ...props }) => {
    const [isOpenProject, setModalOpenProject] = useState(false);
    const [showSubProjectInfo, setShowSubProjectInfo] = useState(null);
    const [showPostulations, setShowPostulations] = useState({});
    const [editProject, setEditProject] = useState(false);
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const [showSubProjectsMenu, setShowSubProjectsMenu] = useState(false);
    const [showPostulationsMenu, setShowPostulationsMenu] = useState(false);
    const [showPostulationModal, setShowPostulationModal] = useState(null);
    const [showBudgetsMenu, setShowBudgetsMenu] = useState(false);
    const [showQuestionsModal, setShowQuestionsModal] = useState(false);
    const [showAnswerModal, setShowAnswerModal] = useState(false);
    const [questionToAnswer, setQuestionToAnswer] = useState(null);
    const [randomImage, setRandomImage] = useState("");
    const [isActive, setIsActive] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const menuRef = useRef(null);
    //Definimos si es proycto propio o de otro usuario
    const isOwnProject = proyecto?.user?.id === userData?.id;

    // Datos de redes sociales
    const { facebook = '', instagram = '', linkedin = '', twitter = '', phone = '' } = proyecto?.user || {};

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowSubProjectsMenu(false);
            setShowPostulationsMenu(false);
            setShowBudgetsMenu(false);
            setIsActive(null);
        }
    };

    useEffect(() => {
        if (proyecto?.proyecto_images && proyecto?.proyecto_images.length === 0) {
            getRandomImage();
        }
        getProjectQuestions(parseInt(id));
    }, [proyecto.id]);

    useEffect(() => {
        if (showSubProjectsMenu || showPostulationsMenu || showBudgetsMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showSubProjectsMenu, showPostulationsMenu, showBudgetsMenu]);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
    };

    // Ejecutar handleResize al cargar el componente
    useEffect(() => {
        handleResize();
    }, []);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    //Formateamos fechas

    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        getPrivateProjectById(id);
        getPostulationProjectStatus(parseInt(id));
        getPostulations(userData.id);
    }, []);

    //Muestra la info de los subproyectos
    const toggleSubProjectInfo = (index) => {
        setShowSubProjectInfo(prevIndex => (prevIndex === index ? null : index));
    };

    //Muestra las postulaciones
    const togglePostulations = (index) => {
        setShowPostulations((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    //Maneja la solicitud de acceso al proyecto
    const handleAccessRequest = (permisoId, status, solicitudType) => {

        validateAccessRequest(permisoId, status, solicitudType);
        setTimeout(() => {
            getPostulationProjectStatus(parseInt(id));
        }, 3000);
    }

    //Abre el modal de Postulacion
    const openPostulationModal = () => {
        setPostulationProjectId(proyecto?.id);
        openNDAModal(true);
    }



    //Define que boton mostrar
    const isPostulationSent = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "SEND");
    const isPostulationAllowed = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "ALLOWED");
    const isPostulationDenied = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "NEGATED");
    const isPostulationAvailable = !isOwnProject && !isPostulationSent && !isPostulationAllowed && !isPostulationDenied;

    // Estado actual de la postulación del usuario
    let userPostulationStatus;
    if (isPostulationSent) {
        userPostulationStatus = isPostulationSent.status;
    } else if (isPostulationAllowed) {
        userPostulationStatus = isPostulationAllowed.status;
    } else if (isPostulationDenied) {
        userPostulationStatus = isPostulationDenied.status;
    }

    const handlePostulationButton = () => {
        if (isPostulationSent) {
            setShowPostulationModal("sent");
        } else if (isPostulationAvailable) {
            openPostulationModal();
        } else if (isPostulationDenied) {
            setShowPostulationModal("denied");
        } else if (isPostulationAllowed) {
            setShowPostulationModal("allowed");
        } else {
            setShowPostulationModal(null);
        }
    }

    const handleHeaderButtonsMenu = (e, value) => {
        e.stopPropagation();
        setShowSubProjectInfo(null);
        setIsActive(null);
        if (value === "subProjects") {
            setShowSubProjectsMenu(!showSubProjectsMenu);
            setShowPostulationsMenu(false);
            setShowBudgetsMenu(false);
            setIsActive(value);
        } else if (value === "postulations") {
            setShowPostulationsMenu(!showPostulationsMenu);
            setShowSubProjectsMenu(false);
            setShowBudgetsMenu(false);
            setIsActive(value);
        } else if (value === "budgets") {
            setShowBudgetsMenu(!showBudgetsMenu);
            setShowSubProjectsMenu(false);
            setShowPostulationsMenu(false);
            setIsActive(value);
        }
    };

    //Muestra el PopUp de respuesta a pregunta
    const handleAnswerQuestion = (question) => {
        setQuestionToAnswer(question);
        setShowAnswerModal(true);
    }

    //Muestra el PopUp de edicion de proyecto 
    const handleEditProject = () => {
        setEditProject(true);
        setProjectToEdit(parseInt(proyecto.id));
    }

    //Borrar el proyecto 
    const handleDeleteProject = () => {
        deleteProyecto(parseInt(proyecto.id), navigate, true);
    }

    //Volver atrás
    const goBack = () => {
        navigate.goBack();
    }

    //Maneja la redirección al perfil del creador del proyecto
    const handleUserRedirect = () => {
        const userId = proyecto?.user?.id;
        const userFullName = proyecto?.user?.type === "COMPANY" ? proyecto?.user.razon_social : proyecto?.user?.name + " " + proyecto?.user?.last_name;
        navigate(`/profesionales/${userId}/${userFullName}`);
    }

    const constructionImages = [
        "https://images.pexels.com/photos/534220/pexels-photo-534220.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/209266/pexels-photo-209266.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/1451416/pexels-photo-1451416.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/534228/pexels-photo-534228.jpeg?auto=compress&cs=tinysrgb&w=600"
    ];

    // Función para obtener una imagen aleatoria
    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * constructionImages.length);
        return setRandomImage(constructionImages[randomIndex]);
    }

    return (
        <main className={styles.mainContainer}>
            {setProjectLoading ? <ProyectoSkeletonLoader /> :
                (
                    <>
                        <ProyectoIdHeader
                            isActive={isActive}
                            isOwnProject={isOwnProject}
                            isPostulationSent={isPostulationSent}
                            isPostulationAllowed={isPostulationAllowed}
                            isPostulationDenied={isPostulationDenied}
                            isPostulationAvailable={isPostulationAvailable}
                            postulacionesDeProyecto={postulacionesDeProyecto}
                            proyecto={proyecto}
                            userPostulationStatus={userPostulationStatus}
                            handleHeaderButtonsMenu={handleHeaderButtonsMenu}
                            showSubProjectsMenu={showSubProjectsMenu}
                            showPostulationsMenu={showPostulationsMenu}
                            showPostulations={showPostulations}
                            showBudgetsMenu={showBudgetsMenu}
                            handlePostulationButton={handlePostulationButton}
                            handleEditProject={handleEditProject}
                            handleDeleteProject={handleDeleteProject}
                            togglePostulations={togglePostulations}
                            handleAccessRequest={handleAccessRequest}
                            menuRef={menuRef}
                            toggleSubProjectInfo={toggleSubProjectInfo}
                            showSubProjectInfo={showSubProjectInfo}
                        />
                        <div className={styles.sectionWrapper}>
                            <section className={styles.cards_datos}>
                                {proyecto?.proyecto_images && proyecto?.proyecto_images.length > 0 ?
                                    <Carousel
                                        showArrows
                                        wrapAround
                                        slidesToShow={1}
                                        className={styles.cards_datos_imageContainer}
                                    >
                                        {proyecto?.proyecto_images.map((image, index) => (
                                            <img className={styles.cards_datos_imageContainer_image} key={index} src={image.image} alt={proyecto.name} />
                                        ))}
                                    </Carousel>
                                    : <div className={styles.cards_datos_imageContainer}>
                                        <img src={randomImage} alt="Imagen de proyecto" className={styles.cards_datos_imageContainer_image} />
                                    </div>
                                }
                                <div className={styles.cards_datos_container}>
                                    <h4 className={styles.cards_datos_title}>{proyecto?.name}</h4>
                                    <p className={styles.cards_datos_container_description}>{proyecto?.description}</p>
                                    <div className={styles.cards_datos_container_body}>
                                        <div className={styles.cards_datos_container_item}>
                                            <div className={styles.cards_datos_container_item_label}>
                                                <img src="https://cdn-icons-png.flaticon.com/128/8239/8239042.png" alt="Ubicacion" className={styles.cards_datos_container_item_label_icon} />
                                                Ubicacion</div>
                                            <p className={styles.cards_datos_container_item_value}>{proyecto?.zone}</p>
                                        </div>
                                        <div className={styles.cards_datos_container_item}>
                                            <div className={styles.cards_datos_container_item_label}>
                                                <img src="https://cdn-icons-png.flaticon.com/128/747/747310.png" alt="Fecha de inicio" className={styles.cards_datos_container_item_label_icon} />
                                                Inicio
                                            </div>
                                            <p className={styles.cards_datos_container_item_value}>{formatDate(proyecto?.startDate)}</p>
                                        </div>
                                        <div className={styles.cards_datos_container_item}>
                                            <div className={styles.cards_datos_container_item_label}>
                                                <img src="https://cdn-icons-png.flaticon.com/128/747/747310.png" alt="Fecha de finalizacion" className={styles.cards_datos_container_item_label_icon} />
                                                Finalizacion
                                            </div>
                                            <p className={styles.cards_datos_container_item_value}>{formatDate(proyecto?.endDate)}</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className={styles.cards}>
                                <div className={styles.cards_user}>
                                    <h3 className={styles.cards_user_title}>
                                        CREADOR
                                        <div className={styles.cards_user_title_pill} />
                                    </h3>
                                    <div className={styles.cards_user_container} onClick={() => handleUserRedirect()}>
                                        <div className={styles.cards_user_container_user}>
                                            <img src={proyecto?.user?.image_profile} alt="Imagen de perfil" className={styles.cards_user_container_user_image} />
                                            <p className={styles.cards_user_container_user_name}>{proyecto?.user?.type === "COMPANY" ?
                                                proyecto?.user?.razon_social : proyecto?.user?.name + " " + proyecto?.user?.last_name}</p>
                                            <div className={styles.cards_user_container_user_socialNetworksContainer_icons}>
                                                {facebook && (
                                                    <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                        <a href={facebook} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                            <FacebookIcon width={20} height={20} />
                                                        </a>
                                                    </div>
                                                )}
                                                {instagram && (
                                                    <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                        <a href={instagram} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                            <InstagramIcon width={20} height={20} />
                                                        </a>
                                                    </div>
                                                )}
                                                {linkedin && (
                                                    <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                        <a href={linkedin} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                            <LinkedinIcon width={20} height={20} />
                                                        </a>
                                                    </div>
                                                )}
                                                {twitter && (
                                                    <div className={styles.cards_user_container_user_socialNetworksContainer_item} >
                                                        <a href={twitter} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                            <TwitterIcon width={20} height={20} />
                                                        </a>
                                                    </div>
                                                )}
                                                {phone && (
                                                    <div className={styles.cards_user_container_user_socialNetworksContainer_item}>
                                                        <a href={`https://wa.me/${phone}`} target="_blank" rel="noreferrer" className={styles.cards_user_container_user_socialNetworksContainer_item_label}>
                                                            <WhatsAppIcon width={20} height={20} />
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.cards_map}>
                                    <Maps ciudad={proyecto?.zone} />
                                </div>
                            </section>
                            <section className={styles.cards_questions}>
                                <h3 className={styles.cards_questions_title}>
                                    PREGUNTAS
                                    <div className={styles.cards_user_title_pill} />
                                </h3>
                                <div className={styles.cards_questions_container}>
                                    {projectQuestions && projectQuestions.length > 0 ? (
                                        projectQuestions.map((question, index) => {
                                            const hasAnswer = !!question.answer;
                                            const isOwnAndNoAnswer = isOwnProject && !hasAnswer;

                                            return (
                                                <div className={styles.cards_questions_container_question} key={index}>
                                                    <p className={styles.cards_questions_container_question_title}>{question.question}</p>
                                                    {isOwnAndNoAnswer ? (
                                                        <button
                                                            className={styles.cards_questions_container_question_answerButton}
                                                            onClick={() => handleAnswerQuestion(question)}
                                                        >
                                                            Responder
                                                        </button>
                                                    ) : (
                                                        <p className={styles.cards_questions_container_question_answer}>
                                                            {hasAnswer ? question.answer : "El creador del proyecto aún no ha respondido"}
                                                        </p>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <EmptyState title="Nadie hizo preguntas ¡Hacé la primera!" icon={<CommentsIcon width={30} height={30} />} />
                                    )}
                                </div>
                                {!isOwnProject && (
                                    <div className={styles.cards_questions_buttonContainer}>
                                        <button className={styles.cards_questions_button} onClick={() => setShowQuestionsModal(true)}>Hacer una pregunta</button>
                                    </div>
                                )}
                            </section>
                        </div>
                    </>
                )}
            {showPostulationModal && (
                <div className={styles.postulationModal}>
                    <section className={styles.postulationModal_body}>
                        <header className={styles.postulationModal_header}>
                            <button className={styles.postulationModal_header_close} onClick={() => setShowPostulationModal(false)}>X</button>
                        </header>
                        <h2 className={styles.postulationModal_body_title}>
                            {showPostulationModal === "sent" && "Solicitud enviada"}
                            {showPostulationModal === "denied" && "Solicitud rechazada"}
                            {showPostulationModal === "allowed" && "Solicitud aceptada"}
                        </h2>
                        <div className={styles.postulationModal_body_imageContainer}>
                            {showPostulationModal === "sent" && <img src={"https://cdn-icons-png.flaticon.com/128/4539/4539472.png"} alt="Sent" className={styles.postulationModal_body_image} />}
                            {showPostulationModal === "denied" && <img src={"https://cdn-icons-png.flaticon.com/128/1828/1828843.png"} alt="Denied" className={styles.postulationModal_body_image} />}
                            {showPostulationModal === "allowed" && <img src={"https://cdn-icons-png.flaticon.com/128/8215/8215539.png"} alt="Accepted" className={styles.postulationModal_body_image} />}
                        </div>
                        <p className={styles.postulationModal_body_txt}>
                            {showPostulationModal === "sent" && "El creador del proyecto aún no te habilitó para cotizar"}
                            {showPostulationModal === "denied" && "El creador del proyecto ha rechazado tu solicitud de participación en el proyecto"}
                            {showPostulationModal === "allowed" && "Ya puedes descargar toda la documentación en la sección 'RUBROS'"} </p>
                    </section>
                </div>
            )}
            <Modal isOpen={isOpenProject}>
                <NewProject closeModal={() => setModalOpenProject(false)} />
            </Modal>
            {showAnswerModal &&
                <ProjectAnswer closeModal={() => setShowAnswerModal(false)} question={questionToAnswer} projectId={proyecto.id} />
            }
            {showQuestionsModal &&
                <ProjectQuestions projectId={proyecto.id} closeModal={() => setShowQuestionsModal(false)} />
            }
            {editProject &&
                <div className={styles.editBackDrop}>
                    <NewProject
                        isEditing
                        handleClose={() => setEditProject(false)}
                        postulationProject={proyecto}
                        closeModal={() => setEditProject(false)}
                    />
                </div>}
            {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
                null}
        </main>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        proyecto: state.profile.proyectoPrivadoPorId,
        postulacionesDeProyecto: state.profile.postulationsInProject,
        postulacionesDelUsuario: state.profile.postulations,
        projectQuestions: state.profile.projectQuestions,
        setProjectLoading: state.profile.setProjectLoading,
    };
}

export default connect(mapStateToProps, {
    getPrivateProjectById,
    openTutorialModal,
    getPostulationProjectStatus,
    validateAccessRequest,
    openNDAModal,
    getPostulations,
    setPostulationProjectId,
    getSubProjectBudgets,
    setProjectToEdit,
    deleteProyecto,
    getProjectQuestions,
})(ProyectoId);
