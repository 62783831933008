import React, { useState, useEffect } from "react";
import styles from "./PortfolioItem.module.scss";
import { connect } from "react-redux";
import { deletePortfolio, getPortfolioQuestions, openMainModal } from "../../../store/actions";
import { ReactComponent as ClockIcon } from "../../../assets/svg/clockIcon.svg";
import { ReactComponent as CommentsIcon } from "../../../assets/svg/commentPost.svg";
import PortfolioItemModal from "./PortfolioItemModal";
import RelativeTimeElement from "@github/relative-time-element";
import PortfolioQuestion from "./PortfolioQuestion";
import PortfolioAnswer from "./PortfolioAnswer";


const PortfolioItem = ({
       portfolio, 
       deletePortfolio, 
       userData,
       myPerson, 
       getPortfolioQuestions, 
       portfolioQuestions, 
       openMainModal }) => {
    const [showPortfolio, setShowPortfolio] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
  
    const coverImage = portfolio.portfolio_images[0].image;
    useEffect(() => {
      if (!portfolioQuestions[portfolio.id]) {
        getPortfolioQuestions(portfolio.id);
      }
    }, [portfolio.id, portfolioQuestions, getPortfolioQuestions]);
  
    // Formateamos fechas
    const formatDate = (date) => {
      const utcDate = new Date(date);
      const options = { day: 'numeric', month: 'short' };
      return utcDate.toLocaleDateString('es-ES', options);
    };

    // Maneja el borrado del portfolio
    const handleDeletePortfolio = () => {
        openMainModal({
            title: "Eliminar portfolio",
            body: "¿Estás seguro que deseas eliminar este publicación de tu perfil?",
            confirmHandler: () => {
                deletePortfolio(parseInt(portfolio.id));
            },
        });
    };

    const portfolioQuestionsForItem = portfolioQuestions[portfolio.id] || [];
    const questionsNotAnswered = portfolioQuestionsForItem.filter((question) => question?.answers?.length === 0);

    return (
      <div className={styles.container}>
        <div className={styles.container_image} onClick={() => setShowPortfolio(true)}>
          <img className={styles.container_image_img} src={coverImage} alt="portfolio" />
          <p className={styles.container_info_location}>{portfolio?.zone}</p>
          <p className={styles.container_info_dates}>
            {formatDate(portfolio?.startDate)} - {formatDate(portfolio?.endDate)}
          </p>
        </div>
        <div className={styles.container_info} onClick={() => setShowPortfolio(true)}>
          <div className={styles.container_info_text}>
            <div className={styles.container_info_text_pill} />
            <h4 className={styles.container_info_text_title}>{portfolio?.name}</h4>
            <p className={styles.container_info_text_description}>{portfolio?.description}</p>
          </div>
          <div className={styles.container_info_footer}>
            <div className={styles.container_info_footer_clock}>
              <ClockIcon width={15} height={15} />
              <relative-time lang="es" tense="past" datetime={portfolio?.createdAt}></relative-time>
            </div>
            <div className={styles.container_info_footer_comments}>
              <CommentsIcon width={15} height={15} />
              <p>{portfolioQuestionsForItem.length} &nbsp;
                    {(portfolioQuestionsForItem.length > 1) || (portfolioQuestionsForItem.length === 0) ? "preguntas" : "pregunta"}</p>
            </div>
          </div>
        </div>
        <div className={styles.container_buttons}>
          {myPerson ? (
            <div className={styles.container_buttons_title}>
                {questionsNotAnswered.length > 0 ? (
                    <>
              <p className={styles.container_buttons_questions}>Tenés {questionsNotAnswered.length} {questionsNotAnswered.length > 1 ? "preguntas nuevas" : "pregunta nueva"}</p>
              <button className={styles.container_buttons_questions_answerBtn} onClick={() => setShowAnswer(true)}>Responder</button>
              </>
                ) : (
                    <p className={styles.container_buttons_questions}>Aún no tienes preguntas para responder</p>
            )}
              <div className={styles.container_buttons_users}>
                <button className={styles.container_buttons_btn} onClick={() => setIsEditing(!isEditing)}>Editar</button>
                <button className={styles.container_buttons_btn} onClick={() => handleDeletePortfolio()}>Eliminar</button>
              </div>
            </div>
          ) : (
            <div className={styles.container_buttons_title}>
              <p className={styles.container_buttons_questions}>¿Tenés preguntas?</p>
              <div className={styles.container_buttons_questions_btnContainer}>
              <button className={styles.container_buttons_questions_btn} onClick={() => setShowQuestions(true)}>Enviar mensaje</button>
              </div>
            </div>
          )}
        </div>
        {showPortfolio && (
          <PortfolioItemModal 
            item={portfolio} 
            myPerson={myPerson}
            userData={userData}
            closeModal={() => setShowPortfolio(false)} 
            portfolioQuestionsForItem={portfolioQuestionsForItem} />
        )}
        {showQuestions && (
            <PortfolioQuestion portfolioId={portfolio.id} closeModal={() => setShowQuestions(false)} userData={userData} />
        )}
        {showAnswer && (
            <PortfolioAnswer portfolioId={portfolio.id} closeModal={() => setShowAnswer(false)} portfolioQuestions={questionsNotAnswered}/>
        )}
      </div>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      portfolioQuestions: state.profile.portfolioQuestions, 
    };
  };
  
  export default connect(mapStateToProps, { deletePortfolio, getPortfolioQuestions, openMainModal })(PortfolioItem);