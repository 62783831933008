import React, { useEffect } from "react";
import RegisterSteps from "../RegisterStepsPage";
import styles from "./SelectType.module.scss";
import { ReactComponent as PersonaIcon } from "../../../assets/svg/Persona.svg";
import { ReactComponent as EmpresaIcon } from "../../../assets/svg/Empresa.svg";
import Button from "../../../components/UI/Forms/Button/Button";
import { connect } from "react-redux";
import { logout } from "../../../store/actions";
import { CHANGE_TYPE } from "../../../store/actions/types";
import InitialWrapper from "../../../components/Layout/InitialWrapper/InitialWrapper";
import { motion } from "framer-motion";
import { fireAnalytics } from "../../../utils/firebase.js";

const SelectType = (props) => {
  useEffect(() => {
    fireAnalytics("ob_step_1", { category: "navigation" });
  }, []);

  const actualYear = new Date().getFullYear();

  const handleType = (type) => {
    if (type === "PERSONA") {
      fireAnalytics("ob_step_1_profesional", { category: "onboarding" });
    } else {
      fireAnalytics("ob_step_1_company", { category: "onboarding" });
    }
  };

  let select = props.type;
  let active = `${styles.typeContainer} ${styles.active}`;
  return (
    <motion.div
      className={styles.container}
      transition={{ ease: "easeInOut", duration: 0.3, delay: 0.5 }}
    >
      <section className={styles.selectType}>
        <div className={styles.selectType_body}>
          <h1 className={styles.title}>
            Tipo de cuenta
          </h1>
          <h3 className={styles.formTitle}>Paso 1</h3>
          <p className={styles.formSubtitle}>
            Elije que tipo de cuenta se ajusta a tu necesidad
          </p>
          <div className={styles.selectTypeContainer}>
            <motion.div
              transition={{ ease: "easeInOut", duration: 0.3, delay: 0.5 }}
              animate={{ opacity: [0, 1] }}
              className={`${select === "PERSONA" ? active : styles.typeContainer
                }`}
              onClick={props.onChangeHandler.bind(this, "PERSONA")}
            >
              {select === "PERSONA" ? <PersonaIcon /> : <PersonaIcon />}
              <div className={styles.buttonType}>Personal</div>
            </motion.div>

            <motion.div
              transition={{ ease: "easeInOut", duration: 0.3, delay: 0.5 }}
              animate={{ opacity: [0, 1] }}
              className={`${select === "EMPRESA" ? active : styles.typeContainer
                }`}
              onClick={props.onChangeHandler.bind(this, "EMPRESA")}
            >
              {select === "EMPRESA" ? <EmpresaIcon /> : <EmpresaIcon />}
              <div className={styles.buttonType}>Empresa</div>
            </motion.div>
          </div>
          <div className={styles.selectTypeContainerButtons}>
            <Button
              typebtn="link"
              link="register-form"
              disabled={select === ""}
              onClick={() => handleType(select)}
            >
              Siguiente
            </Button>
          </div>
          <div>
            <Button
              to="/"
              colored="white"
              typebtn="link"
              onClick={() => logout()}
              style={{ border: "none", padding: "20px 0 0 0" }}
              className={styles.hover}
            >
              <span> Cancelar </span>
            </Button>
          </div>
          <RegisterSteps stepCount={1} progress={"20%"} />
        </div>
        <div className={styles.copyright}>
          Copyright © {actualYear} Edify, All rights reserved. Buenos Aires, Argentina.
        </div>

      </section>
      <section className={styles.background} />
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.register.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeHandler: (tipo) => dispatch({ type: CHANGE_TYPE, payload: tipo }),
    logout,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectType);
