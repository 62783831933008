import React, { useEffect, useState } from "react";
import styles from "./MisComentarios.module.scss";
import EmptyStateComments from "../../../UI/EmptyState/EmptyStateComments";
import { connect } from "react-redux";
import RepAndComments from "./RepAndComments/RepAndComments";
import { ReactComponent as StarFull } from "../../../../assets/svg/starFullSvg.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../../../assets/svg/arrowRightIcon.svg";
import {openCalificationModal, getProfileVisualizer, getCalificationsByUser } from "../../../../store/actions";

const MisComentarios = ({
  perfilAjeno,
  myPerson,
  commentsProfile,
  profileVisualizerData,
  comments,
  openCalificationModal,
  getProfileVisualizer,
  userCalifications,
  getCalificationsByUser,
  ...props }) => {
  const [orderBy, setOrderBy] = useState({ value: "fecha", label: "Fecha" });
  const [rubro, setRubro] = useState({ value: "service", label: "Rubro contratado" });
  const [calificacion, setCalificacion] = useState({value: "Calificación", label: "Calificación"});
  const [isFiltered, setIsFiltered] = useState(false);
  const [commentsFiltered, setCommentsFiltered] = useState([]);
  const [optionsVisibility, setOptionsVisibility] = useState({
    orderBy: false,
    rubro: false,
    calificacion: false
  });

  const handleOrderByChange = (value, label) => {
    setOrderBy({value, label});
    toggleOptionsVisibility("orderBy");
    handleSortByDate(value);
  };

  const handleRubroChange = (value, label) => {
    setRubro({value, label});
    toggleOptionsVisibility("rubro");
    handleFilterByRubro(value);
  };

  const handleCalificacionChange = (value, label) => {
    setCalificacion({ value, label });
    toggleOptionsVisibility("calificacion");
    handleFilterComments(value);
  };
  
  
  const toggleOptionsVisibility = (option) => {
    setOptionsVisibility(prevState => ({
      ...prevState,
      [option]: !prevState[option]
    }));
  };

  const orderByOptions = [
    { value: "fecha", label: "Fecha" },
    { value: "masRecientes", label: "Más recientes" },
    { value: "masAntiguos", label: "Más antiguos" }
  ];

  const handleSortByDate = (value) => {
    if (value === "fecha") {
      setIsFiltered(false);
      return;
    }

    if (isFiltered) {
      if (value === "masRecientes") {
        setCommentsFiltered(commentsFiltered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      } else {
        setCommentsFiltered(commentsFiltered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
      }
    } else {
      if (value === "masRecientes") {
        setCommentsFiltered(userCalifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      } else {
        setCommentsFiltered(userCalifications.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
      }
    }
    setIsFiltered(true);
  };

  useEffect(() => {
    if (orderBy.value !== "fecha") {
      handleSortByDate(orderBy.value);
    }
  }, [orderBy]);

  const groupByRubros = () => {
    const grouped = {};

    if (!Array.isArray(userCalifications)) {
        return grouped;
    }

    userCalifications.forEach(calif => {

        if (Array.isArray(calif.skills)) {
            calif.skills.forEach(skill => {
                const rubro = skill?.skill?.name;

                if (rubro) {
                    grouped[rubro] = (grouped[rubro] || 0) + 1;
                }
            });
        }
    });
    
    return grouped;
};

  const rubroOptions = [
    { value: "service", label: "Rubro contratado" },
    ...Object.entries(groupByRubros()).map(([rubro, count]) => (
      { value: rubro, label: `${rubro} (${count})` }
    ))
  ];


  const groupByStars = () => {
    const grouped = {};

    if (!Array.isArray(userCalifications)) {
      return grouped;
    }
    
    userCalifications.forEach(calif => {
      const stars = calif.stars;
      if (!grouped[stars]) {
        grouped[stars] = 1;
      } else {
        grouped[stars]++;
      }
    });
    return grouped;
  };

  const calificacionOptions = [
    { value: "Calificación", label: "Calificación" },
    ...Object.entries(groupByStars()).map(([stars, count]) => (
      { value: `calificacion${stars}`, label: <><StarFull height={15} width={15} /> {stars} ({count})</> }
    ))
  ];

  const handleFilterComments = (value) => {
    if (value === "Calificación") {
      setIsFiltered(false);
      return;
    }
    
    setIsFiltered(true);
    const calificacionNumber = parseInt(value.replace("calificacion", ""));
    setCommentsFiltered(userCalifications.filter(comment => comment.stars === calificacionNumber));
    
  };

  const handleFilterByRubro = (value) => {
    if (value === "service") {
      setIsFiltered(false);
      return;
    }

    setIsFiltered(true);
    setCommentsFiltered(userCalifications.filter(comment =>
      comment.skills.some(skill => skill.skill.name === value)
  ));
  };

  useEffect(() => {
    if (rubro.value !== "service") {
      handleFilterByRubro(rubro.value);
    }
  }, [rubro]);

  useEffect(() => {
    if (calificacion.value !== "Calificación") {
      handleFilterComments(calificacion.value);
    } 
  }, [calificacion]);

  useEffect(() => {
    getProfileVisualizer(perfilAjeno);
    getCalificationsByUser(parseInt(perfilAjeno));
  }, [perfilAjeno]);

  return (
    <div className={styles.container}>
      <main className={styles.PerfilMain}>
        <section className={styles.PerfilMain_section}>
          <header className={styles.PerfilMain_headerContainer}>       
            <div className={styles.PerfilMain_headerContainer_filterContainer}>
              <div className={styles.PerfilMain_headerContainer_filterByDate}>
                <label className={`${styles.PerfilMain_headerContainer_filterByDate_label} ${optionsVisibility.orderBy && styles.active}`}>Ordenar por:</label>
                <div className={`${styles.customSelect_date} ${optionsVisibility.orderBy && styles.active}`}>
                  <div className={styles.customSelect_customSelectTrigger} onClick={() => toggleOptionsVisibility("orderBy")}>
                    <p className={styles.customSelect_customSelectTrigger_txt}>
                      {orderBy.label === "fecha" ? "Fecha" : orderBy.label}
                    </p>
                    <ArrowDownIcon width={10} height={10} className={optionsVisibility.orderBy === false ? styles.arrowDownIcon : styles.arrowUpIcon} />
                  </div>
                  {optionsVisibility.orderBy && (
                    <div className={styles.customSelect_customSelectOptions}>
                      {orderByOptions.map((option) => (
                        <div
                          key={option.value}
                          className={`${styles.customSelect_customSelectOption} ${option.value === "fecha" && styles.titleDate}`}
                          onClick={() => handleOrderByChange(option.value, option.label)} 
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.PerfilMain_headerContainer_filterByRubro}>
                <div className={`${styles.customSelect_rubro} ${optionsVisibility.rubro && styles.active}`}>
                  <div className={styles.customSelect_customSelectTrigger} onClick={() => toggleOptionsVisibility("rubro")}>
                    <p className={styles.customSelect_customSelectTrigger_txt}>
                      {rubro.label === "service" ? "Rubro contratado" : rubro.label}
                    </p>
                    <ArrowDownIcon width={10} height={10} className={optionsVisibility.rubro === false ? styles.arrowDownIcon : styles.arrowUpIcon} />
                  </div>
                  {optionsVisibility.rubro && (
                    <div className={styles.customSelect_customSelectOptions}>
                      {rubroOptions.map((option) => (
                        <div
                          key={option.value}
                          className={`${styles.customSelect_customSelectOption} ${option.value === "service" && styles.titleDate}`}
                          onClick={() => handleRubroChange(option.value, option.label)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.PerfilMain_headerContainer_filterByCalification}>
                <div className={`${styles.customSelect_calification} ${optionsVisibility.calificacion && styles.active}`}>
                  <div className={styles.customSelect_customSelectTrigger} onClick={() => toggleOptionsVisibility("calificacion")}>
                    <p className={styles.customSelect_customSelectTrigger_txt}>
                      {calificacion.label === "calificacion" ? "Calificación" : calificacion.label}
                    </p>
                    <ArrowDownIcon width={10} height={10} className={optionsVisibility.calificacion === false ? styles.arrowDownIcon : styles.arrowUpIcon} />
                  </div>
                  {optionsVisibility.calificacion && (
                    <div className={styles.customSelect_customSelectOptions}>
                      {calificacionOptions.map((option) => (
                        <div
                          key={option.value}
                          className={`${styles.customSelect_customSelectOption} ${option.value === "stars" && styles.titleDate}`}
                          onClick={() => handleCalificacionChange(option.value, option.label)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!myPerson && (
              <div className={styles.PerfilMain_headerContainer_startReview}>
                <div className={styles.PerfilMain_headerContainer_startReview_button} onClick={() => openCalificationModal(profileVisualizerData)}>
                  Dejar una calificación
                  <ArrowRightIcon width={10} height={10} />
                  </div>                  
              </div>)}
          </header>
          <div className={styles.PerfilMain_content}>
            {Array.isArray(userCalifications) ? (
              userCalifications.length === 0 ? (
                <EmptyStateComments title={myPerson ? "No tienes calificaciónes" : "El usuario no ha recibido calificaciones"} />
              ) : (
                (isFiltered ? commentsFiltered :
                userCalifications).map((comment, index) => {
                  return (
                    <RepAndComments
                      key={`${myPerson}-${index}`}
                      comment={comment}
                    />
                  );
                })
              )
            ) : null}
          </div>
        </section>
      </main>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    perfilAjeno: ownProps.perfilAjeno,
    projectId: ownProps.projectId,
    comments: state.profile.comments,
    commentsProfile: state.profile.comments,
    profileVisualizerData: state.general.profileVisualizer,
    userCalifications: state.profile.userCalifications,
  };
};

export default connect(mapStateToProps, { openCalificationModal, getProfileVisualizer, getCalificationsByUser, })(MisComentarios);