import React from "react";
import Logo from "../../../assets/images/logoBig.png";
import styles from "./InitialWrapper.module.scss";
import { motion } from "framer-motion";

const initialWrapper = (props) => {
  const actualYear = new Date().getFullYear();


  return (
    <section
      className={`${styles.sectionContainer} ${props.animation ? styles.loginAnimation : ""}`}
    >
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div>
            <div className={styles.registerContainer}>
              <div className={styles.registerContainer_body}>
                <motion.div layout className={styles.registerContent}>
                <div
                    className={styles.logo}
                  >
                    <img src={Logo} alt="edify" />
                  </div>
                  <div className={styles.childrenContainer}>{props.children}</div>
                </motion.div>
                <div className={styles.copyright}>
                Copyright © {actualYear} Edify, All rights reserved. Buenos Aires, Argentina.
              </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className={`${styles.content} ${styles.background}`}></div>
      </div>
    </section>
  );
};


 export default initialWrapper; 
