import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  setPersona,
  getSkills,
  sendSkills,
  getUserSkill,
  addSkill,
  removeSkill,
  clearUserSkills
} from "../../store/actions/index";
import { fireAnalytics } from "../../utils/firebase";
import FileUpload from "../FileUpload/FileUpload";
import Button from "../UI/Forms/Button/Button";
import Input from "../UI/Forms/Input/Input";
import styles from "./FormularioPersona.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";

const ButtonContainer = styled.div`
    display: flex;
    a {
      margin-right: 10px;
    }
  `;

const FormularioPersona = ({
  name,
  lastName,
  getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  skills,
  userSkills,
  clearUserSkills,
  tel,
  address,
  webSite,
  file,
  setPersona,
}) => {
  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false);
  const [showSkillsList, setShowSkillsList] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fireAnalytics("ob_step_2", { category: "navigation" });
    getSkills();
    clearUserSkills();
  }, []);

  const webSiteRegex = /(^[a-zA-Z_-]{3})([.]{1})([a-zA-Z0-9_.+-]+)([.]{1})([a-zA-Z_-]+)/;
  const telRegex = /[0-9+]/;
  const personaSchema = Yup.object().shape({
    name: Yup.string().required("El campo es requerido."),
    lastName: Yup.string().required("El campo es requerido."),
    tel: Yup.string()
      .matches(telRegex, "Introduzca un formato válido")
      .min(7, "Ingrese un telefono válido")
      .max(13, "Ingrese un telefono válido"),
    address: Yup.string(),
    webSite: Yup.string().matches(webSiteRegex, "Introduzca un formato válido"),
  });

  const handleSpecialKey = (event) => {
    const filter = /^[a-zA-Z ]*$/;
    if (!filter.test(event.key)) {
      event.preventDefault();
    }
  };

  const matchedSkills = skills?.filter((skill) => skill.name.toLowerCase().includes(inputValue.toLowerCase())) || [];

  const handleShowSuggestions = (value) => {
    if (value.length < 3) {
      setShowSkillsSuggestions(false);
    } else if (value.length >= 3) {
      setShowSkillsSuggestions(true);
    }
    setInputValue(value);
  };

  const handleAddSkill = (id) => {
    addSkill(id);
    setShowSkillsSuggestions(false);
    setInputValue("");
    setShowSkillsList(false);

  };

  return (
    <>
      <Formik
        initialValues={{
          name,
          lastName,
          field: "",
          tel,
          address,
          webSite,
        }}
        validationSchema={personaSchema}
        validateOnChange={true}
        onSubmit={(values) => {
          setPersona(values, file, navigate);
        }}
      >
        {({ handleSubmit, isValid, touched, values, errors }) => (
          <>
            <Form
              className={styles.formContainer}
              onSubmit={handleSubmit}
            >
              <FileUpload userType="person" file={file} name="file" />
              <div className={styles.inputRow}>
                <div className={styles.formGroup}>
                  <Field
                    handleKey={handleSpecialKey}
                    placeholder="Nombre*"
                    type="text"
                    name="name"
                    component={Input}
                    className={`${!errors.name ? "isValid" : "error"}`}
                  />
                </div>
                <div className={styles.formGroup}>
                  <Field
                    handleKey={handleSpecialKey}
                    placeholder="Apellido*"
                    type="text"
                    name="lastName"
                    component={Input}
                    className={`${!errors.lastName ? "isValid" : "error"}`}
                  />
                </div>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.formGroup}>
                  <Field
                    placeholder="Zona de trabajo"
                    type="text"
                    name="address"
                    component={Input}
                    className={`${errors.address ? "error" : ""} ${values.address !== "" && !errors.address ? "isValid" : ""}`}
                  />
                </div>
                <div className={styles.formGroup}>
                  <Field
                    placeholder="Teléfono"
                    type="number"
                    name="tel"
                    component={Input}
                    className={`${errors.tel && "error"} ${values.tel !== "" && !errors.tel ? "isValid" : ""}`}
                  />
                </div>
              </div>
                <div className={styles.formGroup}>
                  <Field
                    placeholder="Página Web: www.example.com"
                    type="text"
                    name="webSite"
                    component={Input}
                    className={`${errors.webSite && "error"} ${values.webSite !== "" && !errors.webSite ? "isValid" : ""}`}
                  />
                  {errors.webSite && (
                    <div className={styles.formGroup_errorMsg}>{errors.webSite}</div>
                  )}
                </div>
                <div className={styles.formGroup_lastOne}>
                  <div className={styles.skillsContainer}>
                    {userSkills?.length > 0 && (
                      <div className={styles.skillsContainer_containerSelected}>
                        <div className={styles.skillsContainer_selected}>
                          {userSkills.map((skill) => {
                            return <div className={styles.skillsContainer_skillSelected} key={skill.id} onClick={() => removeSkill(skill.id)}>{skill.name} X</div>
                          })}
                        </div>
                      </div>
                    )}
                    <div className={`${styles.skillsContainer_inputContainer} ${userSkills.length === 0 && touched.skills && styles.inputError}`}>
                      <Field
                        className={styles.skillsContainer_input}
                        type="text"
                        name="skills"
                        placeholder={userSkills.length === 0 && touched.skills ? "Seleccioná al menos 1 rubro" : "Ingresá al menos 3 caracteres"}
                        value={inputValue}
                        onChange={(e) => handleShowSuggestions(e.target.value)}
                      />
                      <ArrowDownIcon
                        width={15}
                        title="Ver lista completa de rubros"
                        height={15}
                        className={`${styles.arrow} ${showSkillsList ? styles.arrowIconUp : styles.arrowIconDown}`}
                        onClick={() => setShowSkillsList(!showSkillsList)} />
                    </div>
                    {showSkillsList && (
                      <div className={styles.skillsContainer_list}>
                        {skills?.map((skill) => {
                          if (skill.name.toLowerCase().includes(inputValue.toLowerCase())) {
                            return <div className={styles.skillsContainer_list_item} key={skill.id} onClick={() => handleAddSkill(skill.id)}>{skill.name}</div>
                          }
                        })}
                      </div>
                    )}
                    {showSkillsSuggestions && (
                      <div className={styles.skillsContainer_list}>
                        {matchedSkills.length > 0 ? matchedSkills.map((skill) => {
                          return <div className={styles.skillsContainer_list_item} key={skill.id} onClick={() => handleAddSkill(skill.id)}>{skill.name}</div>
                        }
                        ) : <div className={styles.skillsContainer_list_item}>No existe ese rubro</div>}
                      </div>
                    )}
                  </div>
                </div>
              <ButtonContainer>
                <Button
                  colored="white"
                  typebtn="link"
                  to="/select-type"
                  onClick={() =>
                    fireAnalytics("ob_step_2_back", { category: "navigation" })
                  }
                >
                  Volver
                </Button>
                <button
                  className={styles.btn}
                  disabled={!(userSkills?.length > 0 && isValid)}
                  type="submit"
                  onClick={() => {
                    fireAnalytics("ob_step_2_profesional", {
                      category: "onboarding",
                    });
                    sendSkills(userSkills, navigate)
                  }
                  }
                >
                  Siguiente
                </button>
              </ButtonContainer>
            </Form>
          </>
        )}
      </Formik >
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.register.personaForm.name,
    lastName: state.register.personaForm.lastName,
    dniCuil: state.register.personaForm.dniCuil,
    profession: state.register.personaForm.profession,
    tel: state.register.personaForm.tel,
    address: state.register.personaForm.address,
    webSite: state.register.personaForm.webSite,
    file: state.register.file,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
    skillContent: state.register.skillContent,
  };
};

export default connect(mapStateToProps, {
  setPersona, 
  getSkills, 
  sendSkills,
  getUserSkill,
  addSkill,
  removeSkill,
  clearUserSkills
})(FormularioPersona);

