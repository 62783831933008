import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import styles from "./WorkOffer.module.scss";
import { getAllWorkOffers } from "../../store/actions";
import Logo from "../../assets/images/logoBig.png";
import LogoMobile from "../../assets/images/logo-navbar.png";
import { ReactComponent as LocationIcon } from "../../assets/svg/location-input.svg";
import UnderConstructionImage from "../../assets/images/underConstruction.jpg"
import WorkApplyModal from "./WorkApplyModal";

const sliderVariants = {
    incoming: direction => ({
        x: direction > 0 ? "100%" : "-100%",
        scale: 1.2,
        opacity: 0
    }),
    active: { x: 0, scale: 1, opacity: 1 },
    exit: direction => ({
        x: direction > 0 ? "-100%" : "100%",
        scale: 1,
        opacity: 0.2
    })
};

const noSlideVariants = {
    active: { x: 0, scale: 1, opacity: 1 }
};

const sliderTransition = {
    duration: 0.5,
    ease: [0.56, 0.03, 0.12, 1.04]
};

const WorkOffer = ({
    allJobRequests,
    getAllWorkOffers,
    isPhone,
    showForm,
    setShowForm,
    setFormViewInPhone,
}) => {
    const [[workOffersCount, direction], setDirection] = useState([0, 0]);
    const [passedStartDate, setPassedStartDate] = useState(false);
    const [formattedSalary, setFormattedSalary] = useState("");
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [showApplyModal, setShowApplyModal] = useState(false);

    useEffect(() => {
        getAllWorkOffers();
    }, [getAllWorkOffers]);

    useEffect(() => {
        if (!showForm) {
            setIsInitialLoad(false);
        }
    }, [showForm]);

    const handleDirection = (newDirection) => {
        const workOffersLength = allJobRequests?.workOffers?.length || 0;
        let newIndex = workOffersCount + newDirection;

        if (newIndex < 0) {
            newIndex = workOffersLength - 1;
        } else if (newIndex >= workOffersLength) {
            newIndex = 0;
        }

        setDirection([newIndex, newDirection]);
    };

    const currentWorkOffer = useMemo(() => {
        return allJobRequests?.workOffers?.[workOffersCount % (allJobRequests?.workOffers?.length || 1)] || {};
    }, [workOffersCount, allJobRequests]);

    const formatStartDate = (date) => {
        let day = date?.split("T")[0].split("-")[2];
        let month = date?.split("T")[0].split("-")[1];
        let year = date?.split("T")[0].split("-")[0];
        return `${day}/${month}/${year}`;

    }

    useEffect(() => {

        let today = new Date();
        if (currentWorkOffer?.startDate < today) {
            setPassedStartDate(true);
        }
    }, [currentWorkOffer])

    useEffect(() => {

        const salary = currentWorkOffer?.salary ? Math.floor(parseFloat(currentWorkOffer?.salary)) : "0";

        if (currentWorkOffer?.salary === "0.00") {
            setFormattedSalary("$ a convenir");
        } else if (currentWorkOffer?.salaryType === "Hour") {
            setFormattedSalary(`$${salary}/hora`);
        } else if (currentWorkOffer?.salaryType === "Day") {
            setFormattedSalary(`$${salary}/día`);
        } else if (currentWorkOffer?.salaryType === "Week") {
            setFormattedSalary(`$${salary}/semana`);
        } else if (currentWorkOffer?.salaryType === "Month") {
            setFormattedSalary(`$${salary}/mes`);
        } else if (currentWorkOffer?.salaryType === "Job") {
            setFormattedSalary(`$${salary}/trabajo completo`);
        }

    }, [currentWorkOffer])

    // Función para calcular la diferencia de tiempo en minutos, horas, días, semanas y meses
    const calcularDiferenciaTiempo = (fechaCreacion) => {
        const fechaActual = new Date();
        const fechaCreacionPost = new Date(fechaCreacion);
        const fechaCreacionLocal = new Date(fechaCreacionPost.getTime() - (fechaCreacionPost.getTimezoneOffset() * 60000) - (3 * 60 * 60 * 1000));
        let diferencia = fechaActual - fechaCreacionLocal;

        if (diferencia < 0) {
            diferencia = -diferencia;
        }

        const minutos = Math.floor(diferencia / (1000 * 60));
        const horas = Math.floor(diferencia / (1000 * 60 * 60));
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const semanas = Math.floor(dias / 7);
        const meses = Math.floor(dias / 30);

        if (minutos < 1) {
            return "Ahora";
        } else if (minutos < 60) {
            return minutos === 1 ? "1 minuto" : `${minutos} minutos`;
        } else if (horas < 24) {
            return horas === 1 ? "1 hora" : `${horas} horas`;
        } else if (dias < 7) {
            return dias === 1 ? "1 día" : `${dias} días`;
        } else if (semanas < 4) {
            return semanas === 1 ? "1 semana" : `${semanas} semanas`;
        } else if (meses < 12) {
            return meses === 1 ? "1 mes" : `${meses} meses`;
        } else {
            const años = Math.floor(dias / 365);
            return años === 1 ? "1 año" : `${años} años`;
        }
    };

    const handleApply = () => {
        setShowApplyModal(true);
    }

    const formatwokZoneCityname = (workZone) => {
        return workZone.split(",")[0];
    }

    return (
        <main className={styles.main}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 5 }}
                className={styles.headerContainer}>
                <div className={styles.workOffer_logoContainer}>
                    <img src={isPhone ? LogoMobile : Logo} alt="Logo de Edify" className={styles.workOffer_logo} />
                </div>
                <h1 className={styles.workOffer_title}>Búsquedas abiertas</h1>
            </motion.div>
            {isPhone ? (
                allJobRequests?.total > 0 ?
                    (
                        <motion.div
                            key={workOffersCount}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className={styles.workOffer_container}>
                            <motion.div
                                custom={direction}
                                variants={isInitialLoad ? noSlideVariants : sliderVariants}
                                initial={isInitialLoad ? "active" : "incoming"}
                                animate="active"
                                exit={isInitialLoad ? "active" : "exit"}
                                transition={sliderTransition}
                                className={styles.workOffer_card}>
                                
                                <div className={styles.workOffer_cardHeader}>
                                    
                                    <div className={styles.workOffer_cardHeader_imageContainer}>
                                        <img src={currentWorkOffer?.user?.image_profile} alt="Imagen de la oferta de trabajo" className={styles.workOffer_cardHeader_imageContainer_image} />
                                    </div>
                                    <div className={styles.workOffer_cardHeader_info}>
                                        <button className={styles.workOffer_cardHeader_button} onClick={() => handleApply()}>Aplicar</button>
                                    </div>
                                </div>
                                <div className={styles.workOffer_cardTitleContainer}>
                                    <h2 className={styles.workOffer_cardTitleContainer_title}>{currentWorkOffer?.user?.type === "COMPANY" ? currentWorkOffer?.user?.razon_social : currentWorkOffer?.user?.name + " " + currentWorkOffer?.user?.last_name}</h2>
                                    <p className={styles.workOffer_cardTitleContainer_date}>{calcularDiferenciaTiempo(currentWorkOffer?.createdAt)}</p>
                                </div>
                                <div className={styles.workOffer_cardSkillsContainer}>
                                    <p className={styles.workOffer_cardSkillsContainer_title}>Rubros requeridos</p>
                                    <div className={styles.workOffer_cardSkillsContainer_skills}>
                                        {currentWorkOffer?.skill?.map((skill, index) => (
                                            <p key={index} className={styles.workOffer_cardSkillsContainer_skills_skill}>{skill?.skill?.name}</p>
                                        ))}
                                    </div>
                                </div>
                                {currentWorkOffer?.description && (
                                    <div className={styles.workOffer_cardDescriptionContainer}>
                                        <p className={styles.workOffer_cardDescriptionContainer_description}>" {currentWorkOffer?.description} "</p>
                                    </div>
                                )}
                                <div className={styles.workOffer_cardFooterContainer}>
                                    <div className={styles.workOffer_cardFooterContainer_location}>
                                        {currentWorkOffer?.workZones?.map((workZone, index) => (
                                            <div key={index} className={styles.workOffer_cardFooterContainer_location_container}>
                                                <LocationIcon className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                                <p className={styles.workOffer_cardFooterContainer_location_container_text}>{workZone?.jurisdiccion?.name} - {formatwokZoneCityname(workZone?.city)}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.workOffer_cardFooterContainer_startDate}>
                                        <p className={styles.workOffer_cardFooterContainer_startDate_text}>Inicio:</p>
                                        <p className={styles.workOffer_cardFooterContainer_startDate_text}>
                                            {currentWorkOffer?.immediateStart || passedStartDate ? "Inmediato" : formatStartDate(currentWorkOffer?.startDate)}
                                        </p>
                                    </div>
                                    <div className={styles.workOffer_cardFooterContainer_salary}>
                                        <p className={styles.workOffer_cardFooterContainer_salary_text}>{formattedSalary}</p>
                                    </div>
                                    <div className={styles.workOffer_cardFooterContainer_duration}>
                                        <p className={styles.workOffer_cardFooterContainer_duration_text}>Tenés tiempo hasta el {formatStartDate(currentWorkOffer?.activeUntil)}</p>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key={workOffersCount}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className={styles.workOffer_container}>
                                <p className={styles.workOffer_subTitlePhoneView} onClick={() => setFormViewInPhone(false)}>
                                    Quiero inscribirme
                                </p>
                            <motion.div className={styles.workOffer_card}>
                                <img src={UnderConstructionImage} alt="under" className={styles.workOffer_image} />
                                <h2 className={styles.workOffer_image_title}>
                                    Estamos buscando las mejores ofertas para vos
                                </h2>
                                <ul className={styles.workOffer_card_list}>
                                    <li>
                                        Proximamente tendremos muchas ofertas laborales.
                                    </li>
                                    <li>
                                        No te pierdas la oportunidad, inscribirse es GRATIS.
                                    </li>
                                    <li>
                                        ¿Tenés dudas? ¿Consultas? Escribinos a contacto@edify.la
                                    </li>
                                </ul>
                            </motion.div>
                        </motion.div>
                    )) : (
                <AnimatePresence custom={direction} exitBeforeEnter>
                    {!isInitialLoad && (
                        allJobRequests?.total > 0 ?
                            (
                                <motion.div
                                    key={workOffersCount}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className={styles.workOffer_container}>
                                    <motion.div
                                        custom={direction}
                                        variants={isInitialLoad ? noSlideVariants : sliderVariants}
                                        initial={isInitialLoad ? "active" : "incoming"}
                                        animate="active"
                                        exit={isInitialLoad ? "active" : "exit"}
                                        transition={sliderTransition}
                                        className={styles.workOffer_card}>
                                        <div className={styles.workOffer_cardHeader}>
                                            <div className={styles.workOffer_cardHeader_imageContainer}>
                                                <img src={currentWorkOffer?.user?.image_profile} alt="Imagen de la oferta de trabajo" className={styles.workOffer_cardHeader_imageContainer_image} />
                                            </div>
                                            <div className={styles.workOffer_cardHeader_info}>
                                                <button className={styles.workOffer_cardHeader_button} onClick={() => handleApply()}>Aplicar</button>
                                            </div>
                                        </div>
                                        <div className={styles.workOffer_cardTitleContainer}>
                                            <h2 className={styles.workOffer_cardTitleContainer_title}>{currentWorkOffer?.user?.type === "COMPANY" ? currentWorkOffer?.user?.razon_social : currentWorkOffer?.user?.name + " " + currentWorkOffer?.user?.last_name}</h2>
                                            <p className={styles.workOffer_cardTitleContainer_date}>{calcularDiferenciaTiempo(currentWorkOffer?.createdAt)}</p>
                                        </div>
                                        <div className={styles.workOffer_cardSkillsContainer}>
                                            <p className={styles.workOffer_cardSkillsContainer_title}>Rubros requeridos</p>
                                            <div className={styles.workOffer_cardSkillsContainer_skills}>
                                                {currentWorkOffer?.skill?.map((skill, index) => (
                                                    <p key={index} className={styles.workOffer_cardSkillsContainer_skills_skill}>{skill?.skill?.name}</p>
                                                ))}
                                            </div>
                                        </div>
                                        {currentWorkOffer?.description && (
                                            <div className={styles.workOffer_cardDescriptionContainer}>
                                                <p className={styles.workOffer_cardDescriptionContainer_description}>" {currentWorkOffer?.description} "</p>
                                            </div>
                                        )}
                                        <div className={styles.workOffer_cardFooterContainer}>
                                            <div className={styles.workOffer_cardFooterContainer_location}>
                                                {currentWorkOffer?.workZones?.map((workZone, index) => (
                                                    <div key={index} className={styles.workOffer_cardFooterContainer_location_container}>
                                                        <LocationIcon className={styles.workOffer_cardFooterContainer_location_container_icon} />
                                                        <p className={styles.workOffer_cardFooterContainer_location_container_text}>{workZone?.jurisdiccion?.name} - {formatwokZoneCityname(workZone?.city)}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={styles.workOffer_cardFooterContainer_startDate}>
                                                <p className={styles.workOffer_cardFooterContainer_startDate_text}>Inicio:</p>
                                                <p className={styles.workOffer_cardFooterContainer_startDate_text}>
                                                    {currentWorkOffer?.immediateStart || passedStartDate ? "Inmediato" : formatStartDate(currentWorkOffer?.startDate)}
                                                </p>
                                            </div>
                                            <div className={styles.workOffer_cardFooterContainer_salary}>
                                                <p className={styles.workOffer_cardFooterContainer_salary_text}>{formattedSalary}</p>
                                            </div>
                                            <div className={styles.workOffer_cardFooterContainer_duration}>
                                                <p className={styles.workOffer_cardFooterContainer_duration_text}>Tenés tiempo hasta el {formatStartDate(currentWorkOffer?.activeUntil)}</p>
                                            </div>
                                        </div>
                                    </motion.div>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key={workOffersCount}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className={styles.workOffer_container}>
                                    <motion.div className={styles.workOffer_card}>
                                        <img src={UnderConstructionImage} alt="under" className={styles.workOffer_image} />
                                        <h2 className={styles.workOffer_image_title}>
                                            Estamos buscando las mejores ofertas para vos
                                        </h2>
                                        <ul className={styles.workOffer_card_list}>
                                            <li>
                                                Proximamente tendremos muchas ofertas laborales.
                                            </li>
                                            <li>
                                                No te pierdas la oportunidad, inscribirse es GRATIS.
                                            </li>
                                            <li>
                                                ¿Tenés dudas? ¿Consultas? Escribinos a contacto@edify.la
                                            </li>
                                        </ul>
                                    </motion.div>
                                </motion.div>
                            )
                    )}
                </AnimatePresence>
            )}
            {allJobRequests?.total > 0 &&
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 5 }}
                    className={styles.buttons}>
                    <button className={styles.buttons_button} onClick={() => handleDirection(-1)}>Anterior</button>
                    <button className={styles.buttons_button} onClick={() => handleDirection(1)}>Siguiente</button>
                </motion.div>
            }
            {showApplyModal && <WorkApplyModal workOffer={currentWorkOffer} setShowApplyModal={setShowApplyModal} setShowForm={setShowForm} allJobRequests={allJobRequests} />}
        </main>
    );
};

const mapStateToProps = state => {
    return {
        allJobRequests: state.register.allJobRequests
    };
};

export default connect(mapStateToProps, { getAllWorkOffers })(WorkOffer);

