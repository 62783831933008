import * as actionTypes from "../actions/types";
import axios from "../../axios";


export const openMainModalDashboard = (config) => {
  return {
    type: actionTypes.OPEN_MAIN_MODAL,
    payload: config,
  };
};

export const setPreviousLocation = (location) => {
  return {
  type: actionTypes.SET_PREVIOUS_LOCATION,
  payload: location,
  }
}

export const openWorkOfferModal = () => {
  return {
    type: actionTypes.OPEN_WORKOFFER_MODAL,
  };
};

export const closeWorkOfferModal = () => {
  return {
    type: actionTypes.CLOSE_WORKOFFER_MODAL,
  };
};

export const closeTutorialModal = () => {
  return {
    type: actionTypes.CLOSE_TUTORIAL_MODAL,
  };
};

export const openTutorialModal = () => {
  return {
    type: actionTypes.OPEN_TUTORIAL_MODAL,
  };
};

export const resetRecentPost = () => {
  return {
    type: actionTypes.RECENT_POST,
    payload: [],
  };
};

//Acciones para Sección Que Pasa en Edify?

//Obtener total de usuarios 

export const getTotalUsers = () => {
  let tipoUrl = `/user/allUsers`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TOTAL_USERS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//obtener los últimos usuarios registrados

export const getLastNewUsers = () => {
  let tipoUrl = `/user/lastUsers`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_LAST_NEW_USERS,
          payload: res.data,
        });
      })
      .catch((err) => {
      console.log(err);
      });
  };
};

//Obtener total de obras públicas

export const getTotalPublicProjects = () => {
  let tipoUrl = `proyecto/allProjects?type=PUBLIC`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalPublicProjects = res.data.length;
        const lastPublicProject = res.data[res.data.length - 1];
        dispatch({
          type: actionTypes.GET_TOTAL_PUBLIC_PROJECTS,
          payload: {totalPublicProjects, lastPublicProject},
        });
      })
      .catch((err) => {});
  };
};

//Obtener total de obras privadas

export const getTotalPrivateProjects = () => {
  let tipoUrl = `proyecto/allProjects?type=PRIVATE`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalPrivateProjects = res.data[1];
        dispatch({
          type: actionTypes.GET_TOTAL_PRIVATE_PROJECTS,
          payload: totalPrivateProjects,
        });
      })
      .catch((err) => {});
  };
};

//Obtener total de cotizaciones públicas

export const getTotalPublicQuotations = () => {
  let tipoUrl = `quotation/allQuotations?type=PUBLIC`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalPublicQuotations = res.data[1];
        const lastPublicQuotation = res.data[0][res.data[0].length - 1];
        dispatch({
          type: actionTypes.GET_TOTAL_PUBLIC_QUOTATIONS,
          payload: {totalPublicQuotations, lastPublicQuotation},
        });
      })
      .catch((err) => {});
  };
};

//Obtener total de Adjudicaciónes en el último año
export const getTotalAdjudications = () => {
  let tipoUrl = `adjudicados/get/getByFilter?type=PUBLIC&order=DESC&page=1&take=10`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalAdjudications = res.data.itemCount;
        dispatch({
          type: actionTypes.GET_TOTAL_ADJUDICATED_PROJECTS,
          payload: totalAdjudications,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  }
}

//Obtener la última obra pública adjudicada
export const getLastAdjudicatedProject = () => {
  let tipoUrl = `adjudicados/lastAdjudicated `;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const lastAdjudicatedProject = res.data[0];
        dispatch({
          type: actionTypes.GET_LAST_ADJUDICATED_PROJECT,
          payload: lastAdjudicatedProject,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  }
}


//Obtener total de cotizaciones públicas el ultimo mes

export const getTotalNewPublicQuotations = () => {
  let tipoUrl = `quotation/lastQuotations?type=PUBLIC`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalNewPublicQuotations = res.data.length;
        dispatch({
          type: actionTypes.GET_TOTAL_NEW_PUBLIC_QUOTATIONS,
          payload: totalNewPublicQuotations,
        });
      })
      .catch((err) => {});
  };
};

//Obtener total de cotizaciones privadas

export const getTotalPrivateQuotations = () => {
  let tipoUrl = `quotation/allQuotations?type=PRIVATE`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalPrivateQuotations = res.data[1];
        dispatch({
          type: actionTypes.GET_TOTAL_PRIVATE_QUOTATIONS,
          payload: totalPrivateQuotations,
        });
      })
      .catch((err) => {});
  };
};

//Obtener total de cotizaciones privadas el ultimo mes

export const getTotalNewPrivateQuotations = () => {
  let tipoUrl = `quotation/lastQuotations?type=PRIVATE`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        const totalNewPrivateQuotations = res.data.length;
        dispatch({
          type: actionTypes.GET_TOTAL_NEW_PRIVATE_QUOTATIONS,
          payload: totalNewPrivateQuotations,
        });
      })
      .catch((err) => {});
  };
};

//Obtener todos los posteos
export const getPosteos = (order, type) => {  
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};

  return (dispatch) => {
    axios
      .get(`posteo/list?order=${order}&type=${type}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_POSTEOS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Obtener los posteos propios
export const getOwnPosteos = (userId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};

  return (dispatch) => {
    axios
      .get(`posteo/user?userFind=${userId}`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_OWN_POSTEOS,
          payload: res.data,
        });
      })
      .catch((err) => {});
  };
};



//crear un posteo en el muro
export const createPost = (body) => {
  let {
    images,
    video,
    content,
  } = body;
  const formData = new FormData();
  formData.append("content", content);
  formData.append("video", video);
  formData.append("images", images);

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`, "content-type": "multipart/form-data" }};


  return (dispatch) => {
    axios
      .post("/posteo/create", formData, bearerToken)
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu posteo se ha creado con éxito",
            isSuccess: true,
            confirmHandler: null,
          })          
        );
        dispatch({ type: actionTypes.RECENT_POST, payload: res.data})
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Ups!",
            body: "Tu posteo no se ha podido crear, por favor intenta nuevamente o escribenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: false,
            confirmHandler: null,
          })
        );
      });
  };
};

//Editar un posteo 
export const editPosteo = (body) => {
  let {
    id,
    images,
    video,
    content,
  } = body;

  const formData = new FormData();
  formData.append("id", parseInt(id));
  formData.append("content", content);
  formData.append("video", video);
  formData.append("images", images);

  
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`, "content-type": "multipart/form-data" }};

  return (dispatch) => {
    axios
      .patch(`/posteo/update`, formData, bearerToken)
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu posteo se ha editado con éxito",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Ups!",
            body: "Tu posteo no se ha podido editar, por favor intenta nuevamente o escribenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};



//Eliminar un posteo
export const deletePost = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");

  return (dispatch) => {
    axios
      .delete("/posteo/delete", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          idPost: parseInt(id),
        },
      })
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu posteo se ha eliminado con éxito",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Ups!",
            body: "Tu posteo no se ha podido eliminar, por favor intenta nuevamente o escribenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: false,
            confirmHandler: null,
          })
        );
      });
  };
};



//Dar like a un posteo
export const likePost = (id) => {
  const body = {
    post_id: parseInt(id),
  };
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};

  return (dispatch) => {
    axios
      .post("/posteo/like", body, bearerToken)
      .then((res) => {
        dispatch(getPosteos("DESC", "DATE"));
      }
      )
      .catch((err) => {});
  };
};

//Obtener todos los comentarios de un post
export const getPostComments = (postId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};
  return (dispatch) => {
    axios
      .get(`/posteo/comments/${postId}`, bearerToken)
      .then((res) => {
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {});
  };
};


//Crea un comentario en el post
export const createPostComment = (postId, content) => {
  const body = {
    postId: postId,
    content: content,
  };

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};
  return (dispatch) => {
    axios
      .post("/posteo/comment", body, bearerToken)
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu comentario ha sido publicado en el muro",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {});
  };
};

//Edita un comentario en un posteo
export const editPostComment = (commentId, content) => {
  const body = {
    commentId: parseInt(commentId),
    content: content,
  };

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};
  return (dispatch) => {
    axios
      .patch("/posteo/comment/update", body, bearerToken)
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu comentario se ha editado con éxito",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Ups!",
            body: "Tu comentario no se ha podido editar, por favor intenta nuevamente o escribenos a contacto@edify.la para que podamos ayudarte ¡Muchas gracias!",
            isSuccess: false,
            confirmHandler: null,
          })
        );
      });
  };
};

//Dar like a un comentario en un posteo
export const likePostComment = (commentId) => {
  const body = {
    post_comment_id: parseInt(commentId),
  };

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};
  return (dispatch) => {
    axios
      .post("/posteo/comment/like", body, bearerToken)
      .then((res) => {
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {});
  };
};

//Responder a un comentario en un posteo
export const responsePostComment = (postId, commentId, content) => {
  const body = {
    postId: parseInt(postId),
    commentId: parseInt(commentId),
    content: content,
  };

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};
  return (dispatch) => {
    axios
      .post("/posteo/comment/response", body, bearerToken)
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu respuesta ha sido publicada en el muro",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Ups!",
            body: "Tu respuesta no se ha podido publicar, por favor intenta nuevamente o escribenos a contacto@edify.la para que podamos ayudarte ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Elimina un comentario en un posteo
export const deletePostComment = (idComment) => {
  let authToken = localStorage.getItem("access-Token-Edify");

  return (dispatch) => {
    axios
      .delete("/posteo/comment/delete", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          idComment: parseInt(idComment),
        },
      })
      .then((res) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Listo!",
            body: "Tu comentario se ha eliminado con éxito",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPosteos("DESC", "DATE"));
      })
      .catch((err) => {
        dispatch(
          openMainModalDashboard({
            title: "¡Error!",
            body: "Tu comentario no se ha podido eliminar",
            isSuccess: false,
            confirmHandler: null,
          })
        );
      });
  };
};

export const getDashboardProjects = () => (dispatch) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  axios
    .get("/proyecto/get/byFilter", bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_DASHBOARD_PROJECTS,
        payload: res.data.content,
      });
    })
    .catch((err) => {
    });
};

//Filtrador de proyectos públicos
export const filterPublicProjects = (searchParams, page, take) => {

  let orderParam = `${searchParams.sort ? searchParams.sort : "DESC"}`;
  let nameParam = `${searchParams.asociatedWords ? "&nombre=" + searchParams.asociatedWords : ""}`;
  let zoneParam = `${searchParams.zone ? "&zone=" + searchParams.zone : ""}`;
  let minAmountParam = `${searchParams.minAmount ? "&montoMin=" + searchParams.minAmount : ""}`;
  let maxAmountParam = `${searchParams.maxAmount ? "&montoMax=" + searchParams.maxAmount : ""}`;
  let currencyParam = `${searchParams.currency ? "&currency=" + searchParams.currency : ""}`;
  let jurisdictionParam = `${searchParams.jurisdiction ? "&jurisdiccion=" + searchParams.jurisdiction : ""}`;
  let organismParam = `${searchParams.organism ? "&organismo=" + searchParams.organism : ""}`;
  let obra_type = `${searchParams.workType ? "&obra_type=" + searchParams.workType : ""}`;
  let sinceParam = `${searchParams.dateRange?.dateFrom ? "&dateFrom=" + searchParams.dateRange.dateFrom : ""}`;
  let untilParam = `${searchParams.dateRange?.dateTo ? "&dateTo=" + searchParams.dateRange.dateTo : ""}`;
  let tipoUrl = searchParams.projectType === "Adjudicadas" ? `/adjudicados/get/getByFilter?type=PUBLIC&order=${orderParam}&page=${page}&take=${take}`
  : `/proyecto/get/getPublicsByFilter?type=PUBLIC&order=${orderParam}&page=${page}&take=${take}`;

  let sendURL = `${tipoUrl}${nameParam}${zoneParam}${minAmountParam}${maxAmountParam}${currencyParam}${jurisdictionParam}${organismParam}${obra_type}${sinceParam}${untilParam}`;

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DASHBOARD_PROJECTS_LOADING,
      payload: true,
    });
    axios
      .get(sendURL, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_PROJECTS,
          payload: {
            data: res.data.entities,
            itemCount: res?.data?.itemCount,
            take: res?.data?.take,
          }
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const filterQuotations = (searchParams, page, take) => {
  let orderParam = `${searchParams.sort ? searchParams.sort : "DESC"}`;
  let nameParam = `${searchParams.asociatedWords ? "&nombre=" + searchParams.asociatedWords : ""}`;
  let zoneParam = `${searchParams.zone ? "&zone=" + searchParams.zone : ""}`;
  let minAmountParam = searchParams.projectType === "Privadas" ? "" : `${searchParams.minAmount ? "&montoMin=" + searchParams.minAmount : ""}`;
  let maxAmountParam = searchParams.projectType === "Privadas" ? "" : `${searchParams.maxAmount ? "&montoMax=" + searchParams.maxAmount : ""}`;
  let currencyParam = `${searchParams.currency ? "&currency=" + searchParams.currency : ""}`;
  let jurisdictionParam = `${searchParams.jurisdiction ? "&jurisdiccion=" + searchParams.jurisdiction : ""}`;
  let organismParam = `${searchParams.organism ? "&organismo=" + searchParams.organism : ""}`;
  let obra_type = `${searchParams.workType ? "&obra_type=" + searchParams.workType : ""}`;
  let sinceParam = `${searchParams.dateRange?.dateFrom ? "&dateFrom=" + searchParams.dateRange.dateFrom : ""}`;
  let untilParam = `${searchParams.dateRange?.dateTo ? "&dateTo=" + searchParams.dateRange.dateTo : ""}`;
  let tipoUrl = searchParams.projectType === "Privadas" ? `/quotation/getByFilter?type=PRIVATE&order=${orderParam}&page=${page}&take=${take}`
  : `/quotation/get/getPublicsByFilter?type=PUBLIC&order=${orderParam}&page=${page}&take=${take}`;

  let sendURL = `${tipoUrl}${nameParam}${zoneParam}${minAmountParam}${maxAmountParam}${currencyParam}${jurisdictionParam}${organismParam}${obra_type}${sinceParam}${untilParam}`;

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DASHBOARD_PROJECTS_LOADING,
      payload: true,
    });
    axios
      .get(sendURL, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_QUOTATIONS_TYPE,
          payload: res.data,
          page,
          itemCount: res?.data?.itemCount,
          take: res?.data?.take,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const filterPrivateProjects = (searchParams, page, take, clear) => {
  
  let orderParam = `${searchParams.sort ? searchParams.sort : "DESC"}`;
  let zoneParam = `${searchParams.zone ? "&ubicacion=" + searchParams.zone : ""}`;
  let workTypeParam = `${searchParams.workType ? "&subproyecto=" + searchParams.workType : ""}`;
  let sinceParam = `${searchParams.dateRange?.dateFrom ? "&dateFrom=" + searchParams.dateRange.dateFrom : ""}`;
  let untilParam = `${searchParams.dateRange?.dateTo ? "&dateTo=" + searchParams.dateRange.dateTo : ""}`;

  let tipoUrl = `/proyecto/get/getPrivatesByFilter?type=PRIVATE&order=${orderParam}&page=${page}&take=${take}`;

  let sendURL = clear ? `${tipoUrl}` : `${tipoUrl}${zoneParam}${workTypeParam}${sinceParam}${untilParam}`;

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DASHBOARD_PROJECTS_LOADING,
      payload: true,
    });
    axios
      .get(sendURL, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_PROJECTS,
          payload: {
            data: res.data.entities,
            itemCount: res?.data?.itemCount,
            take: res?.data?.take,
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_PROJECTS_LOADING,
          payload: false,
        });
      });
  };
};


//Ver Perfil Externo
const setProfileVisualizerData = (data) => {
  return {
    type: actionTypes.SET_PROFILE_VISUALIZER,
    payload: data,
  };
};

export const getProfileVisualizer = (id) => {
  let tipoUrl = `/user/data?userFind=${id}`;
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(tipoUrl, bearerToken)
      .then((res) => {
        dispatch(setProfileVisualizerData({ ...res.data, id: id }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const removeFilter = (filterType) => {
  return {
    type: actionTypes.REMOVE_FILTER,
    payload: filterType,
  };
};
export const resetFilterBy = () => {
  return (dispatch) => {
    dispatch(getDashboardProjects());
    dispatch({ type: actionTypes.RESET_FILTERBY });
  };
};

export const filterByRemove = (searchParams, typeParam) => {
  let tipoUrl = `/proyecto/get/byFilter`;

  let zone, project, since, until, subproject;

  let newFilterSearch = searchParams.filter(
    (itemFilter) => itemFilter.type !== typeParam
  );

  newFilterSearch.forEach((item) => {
    if (item.type === "byName") project = item.value;
    if (item.type === "byZone") zone = item.value;
    if (item.type === "bySubproject") subproject = item.value;
    if (item.type === "byDate") {
      since = item.value.since;
      until = item.value.until;
    }
  });

  let zoneParam = `${zone ? "zone=" + zone : ""}`;
  let projectParam = `${project ? "project=" + project : ""}`;
  let sinceParam = `${until ? "until=" + until : ""}`;
  let untilParam = `${since ? "since=" + since : ""}`;
  let searchByDateURL = `${sinceParam ? sinceParam : ""}&${
    untilParam ? untilParam : ""
  }`;
  let subProjectParam = `${subproject ? "subProjectType=" + subproject : ""}`;

  let sendURL = `${tipoUrl}/?${zoneParam}&${projectParam}&${searchByDateURL}&${subProjectParam}`;

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(sendURL, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_PROJECTS,
          payload: res.data.content,
        });
        dispatch(removeFilter(typeParam));
      })
      .catch((error) => {});
  };
};

export const filterSubprojectList = (filter) => {
  return {
    type: actionTypes.FILTER_SUBPROJECT_LIST,
    payload: filter,
  };
};
export const clearProjects = () => {
  return {
    type: actionTypes.CLEAR_PROJECTS,
  };
};

export const setResponsiveFall = (boolean) => {
  return {
    type: actionTypes.SET_RESPONSIVE_FALL,
    payload: boolean,
  };
};

export const disablePostulateButton = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DISABLE_POSTULATE_BUTTON,
      payload: boolean,
    });
  };
};



